<template>
  <section>
    <div class="container px-4 md:px-6 py-20">
      <div
        class="pt-[75px] px-4 md:px-8 pb-20 border sm:border-2 border-FrenchGrey rounded"
      >
        <div class="mb-24">
          <div
            class="text-Grey text-base md:text-2xl font-basisGrotesquePro-medium mb-20"
          >
            <h2
              class="text-Dune text-xl md:text-[2rem] font-basisGrotesquePro-bold mb-6"
            >
              Grant Writer
            </h2>
            <div class="flex flex-col gap-5">
              <p class="flex items-center gap-2">
                <img
                  src="../../assets/svgs/location-icon.svg"
                  alt="location icon"
                />
                <span>Turbham Foundation House, Abuja Nigeria.</span>
              </p>
              <p class="flex items-center gap-2">
                <img src="../../assets/svgs/pay-icon.svg" alt="pay icon" />
                <span
                  >2,000,000 NGN to 3,000,000 NGN per Annum(depending on
                  experience)</span
                >
              </p>
            </div>
          </div>

          <div
            class="text-sm md:text-2xl font-basisGrotesquePro flex flex-col gap-16"
          >
            <div>
              <h3
                class="text-base md:text-2xl font-basisGrotesquePro-bold mb-5"
              >
                About the job
              </h3>
              <p>
                Turbham Foundation** is actively seeking a motivated and
                talented Grant Writer to play a crucial role in securing grant
                funding for its initiatives. The Grant Writer will be an
                integral member of Turbham Foundation, providing grant writing
                support to advance initiatives in collaboration with the
                foundation's mission. The Grant Writer will work closely with
                the local municipalities and stakeholders associated with
                Turbham Foundation.
              </p>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">Responsibilities</h4>

              <ul class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-10">
                <li>
                  Prepare high-quality grant proposals: Conduct research on
                  projects, collaborate with project proponents, develop
                  budgets, timelines, work plans, and other required documents.
                  Make presentations and secure letters of support as needed.
                </li>

                <li>
                  Project and Grant Tracking: Maintain a systematic approach to
                  track projects and grant applications, ensuring deadlines,
                  application requirements, budgets, and reporting requirements
                  are managed efficiently. Manage multiple grant applications
                  simultaneously and ensure timely submissions.
                </li>

                <li>
                  Project and Grant Tracking: Maintain a systematic approach to
                  track projects and grant applications, ensuring deadlines,
                  application requirements, budgets, and reporting requirements
                  are managed efficiently. Manage multiple grant applications
                  simultaneously and ensure timely submissions.
                </li>

                <li>
                  Submit Reports: After the completion of projects, submit
                  reports to granting organizations following their specific
                  requirements.
                </li>

                <li>
                  Recognition and Communication: Provide recognition for
                  granting organizations as required and communicate effectively
                  with stakeholders.
                </li>

                <li>
                  Monthly Work Plan: Collaborate with the Turbham Foundation
                  team to create a monthly work plan. Participate in monthly
                  meetings with key stakeholders to discuss and refine work
                  plans.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                Qualifications and Experience
              </h4>

              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Excellent Writing Skills: Craft funding proposals in a clear
                  and compelling manner.
                </li>
                <li>
                  Research and Writing Skills: Strong research and writing
                  skills are essential.
                </li>
                <li>
                  Self-Motivated and Organized: Must be self-motivated,
                  detail-oriented, highly organized, and able to work well under
                  pressure.
                </li>
                <li>
                  Computer Literacy: High level of computer literacy, including
                  MS Office, Excel, Power Point, Adobe Pro, etc.
                </li>
                <li>
                  Grant Writing Experience: Previous experience in developing,
                  implementing, and reporting on grant applications.
                </li>
                <li>
                  Communication Skills: Excellent communication, computer, and
                  interpersonal skills.
                </li>
                <li>
                  Independence: Ability to work independently while establishing
                  and maintaining collaborative working relationships with
                  multiple stakeholders.
                </li>
                <li>
                  Understanding of Funding Mechanisms: Familiarity with funding
                  mechanisms for municipalities and non-profit societies.
                </li>
                <li>
                  Meeting Facilitation: Ability to organize and facilitate
                  meetings.
                </li>
                <li>
                  Time Management: Excellent time management and organization
                  skills, with attention to detail and ability to multitask.
                </li>
                <li>
                  Budget Management: Experience working with budgets and
                  managing project work plans.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="font-basisGrotesquePro-bold">How to Apply:</h4>
              <p>
                To apply, please submit your resume, cover letter, and examples
                of successful grant applications to
                recruiting@turbhamfoundation.org. Turbham Foundation is an equal
                opportunity employer, and we encourage individuals from diverse
                backgrounds to apply.
              </p>
            </div>
          </div>
        </div>

        <div>
          <router-link
            to="#"
            class="font-basisGrotesquePro-bold bg-GreenHaze p-4 md:py-5 md:px-[26px] rounded-[40px] text-white text-base text-center"
          >
            Apply Now
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>
