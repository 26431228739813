<template>
  <footer class="bg-LinkWater">
    <div class="container px-4 md:px-6 pt-[92px] pb-28">
      <div
        class="flex flex-col lg:flex-row justify-between gap-y-8 gap-x-10 mb-6"
      >
        <router-link to="/">
          <img
            src="../assets/svgs/TF-logo.svg"
            alt="Turbham Foundation logo"
            class="w-[140px] md:w-[180px]"
          />
        </router-link>

        <div class="flex flex-wrap gap-x-10 gap-y-6 md:gap-x-20 md:gap-y-10">
          <div>
            <h3
              class="font-libreBaskerville text-xs md:text-lg font-bold mb-3 md:mb-10"
            >
              Company
            </h3>
            <div class="flex flex-col gap-[14px] text-xs md:text-[0.8rem]">
              <router-link to="/about-us">About us</router-link>
              <router-link to="/mission-and-vision">Our Mission</router-link>
              <router-link to="/mission-and-vision">Our Vision</router-link>
            </div>
          </div>

          <div>
            <h3
              class="font-libreBaskerville text-xs md:text-lg font-bold mb-3 md:mb-10"
            >
              Projects
            </h3>
            <div class="flex flex-col gap-[14px] text-xs md:text-[0.8rem]">
              <router-link to="/projects">Projects Updates</router-link>
              <router-link to="/media-gallery">Media Gallery</router-link>
              <router-link to="/success-stories">Success Stories</router-link>
            </div>
          </div>

          <div>
            <h3
              class="font-libreBaskerville text-xs md:text-lg font-bold mb-3 md:mb-10"
            >
              Get Involved
            </h3>
            <div class="flex flex-col gap-[14px] text-xs md:text-[0.8rem]">
              <router-link to="/donate">Donate</router-link>
              <router-link to="/volunteer">Volunteer with us</router-link>
              <router-link to="/volunteer-pledge">Volunteer Pledge</router-link>
              <router-link to="/partner-with-us">Partner with us</router-link>
            </div>
          </div>

          <div>
            <h3
              class="font-libreBaskerville text-xs md:text-lg font-bold mb-3 md:mb-10"
            >
              Contact Us
            </h3>
            <div class="flex flex-col gap-[14px] text-xs md:text-[0.8rem]">
              <a href="mailto:prd@turbhamfoundation.org"
                >prd@TurbhamFoundation.org</a
              >
              <a
                href="https://api.whatsapp.com/send?phone=2349090900919"
                target="_blank"
                >+234 90 909 009 19</a
              >
              <router-link to="/faqs">FAQs</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="flex md:justify-end mb-12 lg:mb-52">
        <div class="flex items-center gap-[22px]">
          <a href="https://linkedin.com/company/Turbhamfdn" target="_blank">
            <img
              src="../assets/svgs/linkedIn.svg"
              alt="LinkedIn icon"
              class="w-4 md:w-[31px]"
            />
          </a>
          <a href="https://twitter.com/TurbhamFdn" target="_blank">
            <img
              src="../assets/svgs/x.svg"
              alt="X icon"
              class="w-4 md:w-[31px]"
            />
          </a>
          <a href="https://www.instagram.com/Turbhamfdn" target="_blank">
            <img
              src="../assets/svgs/instagram.svg"
              alt="Instagram icon"
              class="w-4 md:w-[31px]"
            />
          </a>
          <a href="https://www.facebook.com/TurbhamFdn" target="_blank">
            <img
              src="../assets/svgs/facebook.svg"
              alt="Facebook icon"
              class="w-4 md:w-[31px]"
            />
          </a>
        </div>
      </div>

      <div
        class="flex justify-between items-center gap-10 text-[0.625rem] md:text-lg"
      >
        <div class="hidden lg:flex gap-5">
          <div class="flex items-center gap-3">
            <span class="bg-Dune w-6 h-6 rounded-full"></span>
            <p>Terms of Service</p>
          </div>
          <div class="flex items-center gap-3">
            <span class="bg-Dune w-6 h-6 rounded-full"></span>
            <p>Privacy Policy</p>
          </div>
          <div class="flex items-center gap-3">
            <span class="bg-Dune w-6 h-6 rounded-full"></span>
            <p>Cookies</p>
          </div>
        </div>

        <p>© 2023 Turbham Foundation by Turbham Technologies Limited.</p>
      </div>
    </div>
  </footer>
</template>
