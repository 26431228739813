<template>
  <section class="min-h-screen">
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <div class="text-center mb-16 md:mb-36">
        <h2
          class="font-libreBaskerville font-bold text-[2rem] md:text-5xl lg:text-[6.25rem] mb-6 md:mb-7"
        >
          Success
          <span class="inline-flex items-center"
            >St<ColoredCircle
              class="bg-HalloweenOrange w-6 h-6 lg:w-[60px] lg:h-[60px]"
            />r<span
              class="text-Dune relative after:content-['\00a0'] after:absolute after:top-3 md:after:top-1 lg:after:top-2.5 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-2 after:h-2 md:after:w-3 md:after:h-3 lg:after:w-5 lg:after:h-5 after:bg-GreenHaze after:rounded-full"
              >i</span
            >es</span
          >
        </h2>
        <p
          class="md:max-w-[769px] mx-auto text-DavyGrey text-base md:text-lg lg:text-[1.375rem]"
        >
          Read inspiring success stories that highlight the real-world changes
          brought about by Turbham Foundation. Be a part of the stories of
          transformation.
        </p>
      </div>

      <div class="overflow-x-scroll no-scrollbar">
        <div
          class="w-max flex flex-nowrap gap-8 animate-storiesCarousel image-carousel motion-reduce:animate-none motion-reduce:flex-wrap"
        >
          <Card
            v-for="(item, index) in cardsInfo"
            :key="index"
            :cardDetails="item"
          />
          <Card
            v-for="(item, index) in cardsInfo"
            :key="index"
            :cardDetails="item"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "@/components/ui/ColoredCircle.vue";
import Card from "./Card.vue";
import image1 from "@/assets/images/abdul-salam.png";
import image2 from "@/assets/images/omolara.png";
import image3 from "@/assets/images/musa.png";
import image4 from "@/assets/images/madam-tanko.png";
import image5 from "@/assets/images/fatima.png";
import image6 from "@/assets/images/yusuf.png";
import image7 from "@/assets/images/adamu.png";
import image8 from "@/assets/images/ahmed.png";
import image9 from "@/assets/images/sarah.png";
import image10 from "@/assets/images/halima.png";
import image11 from "@/assets/images/sani.png";
import image12 from "@/assets/images/second-fatima.png";
import image13 from "@/assets/images/mariam.png";
import image14 from "@/assets/images/rahmat.png";

const cardsInfo = [
  {
    image: image1,
    name: "Abdul Salam",
    bio: "A farmer in the rural area of Bwari LGA, discovered that he suffers from high blood pressure during Turbham Foundation's outreach, enabling him to seek timely medical intervention and transform his health.",
  },
  {
    image: image2,
    name: "Omolara",
    bio: "At the Turbham Foundations initiative in Asokoro LGA, we uncovered Omolara's journey, where she bravely faced elevated blood glucose levels. Witnessing her transformative lifestyle changes was not just inspiring but downright captivating.",
  },
  {
    image: image3,
    name: "Musa",
    bio: "A farmer in the rural area of Bwari LGA, discovered that he suffers from high blood pressure during Turbham Foundation's outreach, enabling him to seek timely medical intervention and transform his health.",
  },
  {
    image: image4,
    name: "Madam Tanko",
    bio: "In the heart of Zhiko community, the Turbham Foundation's Root & Relief program went beyond identifying Madam Tanko's diabetes; it became a catalyst for empowerment.",
  },
  {
    image: image5,
    name: "Fatima",
    bio: "During a health screening organized by Turbham Foundation, Fatima, a talented seamstress, was confronted with the revelation of elevated blood pressure. This pivotal moment didn't deter her; instead, it ignited a journey of empowerment.",
  },
  {
    image: image6,
    name: "Yusuf",
    bio: "In Turbham Foundation's community outreach, Yusuf's health journey unfolded organically. His elevated blood glucose levels became a turning point, compelling him to take charge of his well-being.",
  },
  {
    image: image7,
    name: "Adamu",
    bio: "Turbham Foundation's medical outreach in Adamu's village, located on the outskirts of Abuja, his vitals were meticulously examined, revealing the presence of hypertension. Prompt medical intervention and proper treatment were administered, leading to Adamu's complete recovery.",
  },
  {
    image: image8,
    name: "Ahmed",
    bio: "Ahmed, a revered community leader, found himself facing elevated cholesterol levels. Instead of allowing this revelation to be a personal setback, Ahmed turned it into a rallying cry for positive change within his village.",
  },
  {
    image: image9,
    name: "Sarah",
    bio: "In Sarah's academic journey, Turbham Foundation's community health screening emerged as a pivotal chapter. Uncovering her elevated BMI at an early stage became a catalyst for a purposeful transformation rather than a setback.",
  },
  {
    image: image10,
    name: "Halima",
    bio: "Turbham Foundation's vital testing played a pivotal role in Halima's pregnancy journey. The timely identification of gestational diabetes became a lifeline, averting potential complications and paving the way for a healthier pregnancy and delivery for both mother and baby.",
  },
  {
    image: image11,
    name: "Sani",
    bio: "A local mechanic, found a significant shift in his approach to health through Turbham Foundation's outreach. The discovery of hypertension wasn't just a diagnosis for him; it became a starting point for positive change.",
  },
  {
    image: image12,
    name: "Fatima",
    bio: "After undergoing medical testing with Turbham Foundation, Fatima, a market vendor, received unexpected news about her potential risk for hypertension. This revelation hit close to home, stirring a genuine concern for her well-being.",
  },
  {
    image: image13,
    name: "Mariam",
    bio: "In a significant moment facilitated by Turbham Foundation's initiative, Mariam, a devoted housewife, discovered her high blood glucose levels. Rather than feeling disheartened, Mariam embraced this revelation as an opportunity for positive transformation.",
  },
  {
    image: image14,
    name: "Rahmat",
    bio: "In a transformative turn of events, Turbham Foundation's outreach brought to light Rahmat's elevated BMI. Rather than letting this revelation dampen her spirits, Rahmat saw it as an opportunity to spark change within her community.",
  },
];
</script>
