<template>
  <section>
    <div class="min-h-screen container px-4 md:px-6 py-28">
      <div
        class="flex flex-col lg:flex-row justify-between gap-5 md:gap-10 md:mb-11"
      >
        <h1
          class="font-libreBaskerville font-bold md:font-normal text-[2rem] md:text-7xl lg:text-[6.25rem] text-center lg:text-left"
        >
          What we d<ColoredCircle
            class="bg-HalloweenOrange w-5 h-5 md:w-12 md:h-12 lg:w-14 lg:h-14"
          />
          t<ColoredCircle
            class="bg-HalloweenOrange w-5 h-5 md:w-12 md:h-12 lg:w-14 lg:h-14"
          />
          make
          <span class="inline-flex justify-center"
            ><span
              class="text-Dune relative after:content-['\00a0'] after:absolute after:top-2.5 after:left-[60%] after:-translate-x-1/2 after:-translate-y-1/2 after:w-[9px] after:h-[9px] after:bg-MediumPink after:rounded-full md:after:hidden"
              >i</span
            >mpact?</span
          >
        </h1>
        <p
          class="lg:self-end lg:max-w-[549px] text-DavyGrey text-sm md:text-[1.375rem] text-center lg:text-left md:leading-[28.6px]"
        >
          Here we believe in the transformative power of connection, compassion
          and collective action. Your journey towards making meaningful impact
          starts here.
        </p>
      </div>

      <Impact
        v-for="(item, index) in impacts"
        :key="index"
        :index="index"
        :item="item"
      />
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "../../ui/ColoredCircle.vue";
import Impact from "./Impact.vue";
import image1 from "@/assets/images/children-running.png";
import image2 from "@/assets/images/hands-together.png";
import image3 from "@/assets/images/lady-with-a-microphone.png";
import image4 from "@/assets/images/children-holding-a-laptop.png";

const impacts = [
  {
    title: "Providing Impactful Relief in Nigeria",
    paragraphs: [
      "Turbham Foundation Nigeria is focused on delivering meaningful and sustainable solutions that directly address the specific needs of our communities. Our goal is to make a lasting positive impact on the lives of Nigerians, considering the diverse cultural and regional contexts.",
    ],
    image: image1,
  },
  {
    title: "Upholding Human Right in the Nigeria Context",
    paragraphs: [
      "We champion the principles of human rights, recognizing the cultural diversity of Nigeria. Our efforts include advocating for the rights and dignity of individuals in the unique socio-cultural landscape of the country.",
    ],
    image: image2,
  },
  {
    title: "Promoting Judicial Fairness in Nigeria",
    paragraphs: [
      "Turbham Foundation Nigeria actively works towards fostering fairness and impartiality within the Nigerian legal system. We believe in promoting equitable access to justice, ensuring that all citizens can benefit from a legal framework that serves their needs.",
    ],
    image: image3,
  },
  {
    title: "Fostering Opportunities for Thriving in Nigeria",
    paragraphs: [
      "Our vision extends to a Nigeria where every citizen, regardless of their background, has the opportunity to thrive. This involves addressing specific challenges within the country, creating pathways for personal and collective growth, and contributing to the development of a flourishing society.",
    ],
    image: image4,
  },
];
</script>
