<template>
  <aside
    class="md:hidden bg-white fixed top-0 left-0 w-full min-h-screen z-20 transition-all duration-300"
    :class="showSidebar ? 'translate-x-0' : '-translate-x-full'"
  >
    <div class="pt-9 px-4">
      <div class="flex justify-between items-center">
        <div>
          <img
            src="../assets/svgs/TF-logo.svg"
            alt="Turbham Foundation logo"
            class="w-[147px]"
          />
        </div>

        <button @click="closeSidebar">
          <span
            class="inline-block w-7 h-[2px] bg-transparent relative -top-1 before:absolute before:w-full before:h-full before:bg-DarkJungleGreen before:left-0 before:top-0 before:-rotate-45 after:absolute after:w-full after:h-full after:bg-DarkJungleGreen after:left-0 after:top-0 after:rotate-45"
          ></span>
        </button>
      </div>

      <div class="mt-10 flex flex-col">
        <router-link to="/" class="py-[18px] text-xl border-y border-WhiteSmoke"
          >Home</router-link
        >
        <router-link
          v-for="(route, index) in headerRoutes"
          :key="index"
          :to="route.path"
          class="py-[18px] text-xl border-y border-WhiteSmoke"
          >{{ route.text }}</router-link
        >
      </div>

      <div class="mt-[106px] max-w-[375px] mx-auto">
        <router-link
          to="/donate"
          class="inline-block bg-GreenHaze w-full p-4 rounded-[50px] text-white text-base text-center"
        >
          Donate Now
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script setup>
import { inject } from "vue";
import { useRouter } from "vue-router";
import useRoutes from "@/composables/useRoutes";

const { headerRoutes } = useRoutes();

const showSidebar = inject("showSidebar");
const router = useRouter();

const closeSidebar = () => {
  showSidebar.value = false;
};

router.afterEach(() => closeSidebar());
</script>
