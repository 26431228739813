<template>
  <section>
    <div class="container px-4 md:px-6 py-20">
      <div class="max-w-[1110px] mx-auto">
        <div
          class="flex flex-col sm:flex-row justify-between items-center gap-y-8 gap-x-4 text-sm sm:text-2xl mb-16 sm:mb-28"
        >
          <div class="w-full flex items-center gap-10">
            <div class="w-full max-w-[312px]">
              <select
                name="department"
                id="department"
                class="bg-LightGrey2 w-full text-Dune py-[10px] px-8 outline-none"
                v-model="filterBy"
              >
                <option value="">Departments</option>
                <option
                  v-for="(department, index) in departments"
                  :key="index"
                  :value="department"
                >
                  {{ department }}
                </option>
              </select>
            </div>
            <div class="w-full max-w-[312px]">
              <select
                name="location"
                id="location"
                class="bg-LightGrey2 w-full text-Dune py-[10px] px-8 outline-none"
              >
                <option value="">Location</option>
                <option
                  v-for="(location, index) in locations"
                  :key="index"
                  :value="location"
                >
                  {{ location }}
                </option>
              </select>
            </div>
          </div>

          <button
            class="bg-GreenHaze w-full sm:w-fit text-white font-basisGrotesquePro-bold py-[10px] px-8 rounded sm:rounded-[40px]"
          >
            Search
          </button>
        </div>

        <div>
          <div
            class="flex flex-col gap-10 text-Dune text-xs sm:text-2xl font-basisGrotesquePro-semibold"
          >
            <div
              v-for="(item, index) in filteredPositions"
              :key="index"
              class="pt-[14px] sm:pt-4 px-[10px] sm:px-10 border sm:border-[3px] border-FrenchGrey rounded-sm relative transition-all duration-500"
              :class="
                item.isTabOpened ? 'pb-7 sm:pb-[52px]' : 'pb-[14px] sm:pb-4'
              "
            >
              <ColoredCircle
                class="hidden md:block bg-GreenHaze absolute left-0 top-1 -translate-x-1/2 w-[57px] h-[57px]"
              />
              <div>
                <div class="flex justify-between items-center gap-2 sm:pl-2">
                  <h4>{{ item.title }}</h4>
                  <button
                    class="flex items-center gap-5 md:text-base font-basisGrotesquePro"
                    @click="item.isTabOpened = !item.isTabOpened"
                  >
                    <span
                      >{{ item.positions.length }} Position{{
                        item.number > 1 ? "s" : ""
                      }}</span
                    >
                    <img
                      src="../../assets/svgs/dropdown-arrow-black.svg"
                      alt="dropdown arrow"
                      class="transition-all duration-500"
                      :class="item.isTabOpened ? 'rotate-180' : 'rotate-0'"
                    />
                  </button>
                </div>

                <div
                  class="wrapper"
                  :class="item.isTabOpened && 'is-open mt-8 sm:mt-9'"
                >
                  <div class="flex flex-col gap-4 overflow-hidden">
                    <router-link
                      v-if="item.positions.length > 0"
                      v-for="(position, index) in item.positions"
                      :key="index"
                      :to="`/opportunities/${position.path}`"
                      class="py-2 px-3 sm:p-4 border sm:border-[3px] border-FrenchGrey rounded-sm flex justify-between items-center"
                    >
                      <div>
                        <h5
                          class="font-basisGrotesquePro-medium text-[0.625rem] sm:text-xl mb-2"
                        >
                          {{ position.title }}
                        </h5>
                        <p
                          class="font-basisGrotesquePro-medium text-FrenchGrey text-[0.5rem] sm:text-base"
                        >
                          {{ position.location }}
                        </p>
                      </div>

                      <div>
                        <img
                          src="../../assets/svgs/arrow-right.svg"
                          alt="arrow right"
                        />
                      </div>
                    </router-link>

                    <div
                      v-else
                      class="py-4 px-3 sm:py-8 sm:px-4 border sm:border-[3px] border-FrenchGrey rounded-sm flex justify-center items-center"
                    >
                      <p
                        class="font-basisGrotesquePro-medium text-FrenchGrey text-[0.625rem] sm:text-lg"
                      >
                        No jobs listed yet, check back later.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, ref } from "vue";
import ColoredCircle from "@/components/ui/ColoredCircle.vue";

const filterBy = ref("");

const allPositions = ref([
  {
    title: "Grant and Funding",
    positions: [
      {
        title: "Grant Writer",
        location: "Abuja, Nigeria",
        path: "grant-writer",
      },
    ],
    isTabOpened: false,
  },
  {
    title: "Supporter Service",
    positions: [
      {
        title: "Volunteer Coordinator",
        location: "Abuja, Nigeria",
        path: "volunteer-coordinator",
      },
    ],
    isTabOpened: false,
  },
  {
    title: "Programs and Projects",
    positions: [],
    isTabOpened: false,
  },
  {
    title: "Technology and IT",
    positions: [],
    isTabOpened: false,
  },
  {
    title: "Event Planning",
    positions: [],
    isTabOpened: false,
  },
  {
    title: "Fundraising and Development",
    positions: [
      {
        title: "Fundraising Development Manager",
        location: "Abuja, Nigeria",
        path: "fundraising-development-manager",
      },
    ],
    isTabOpened: false,
  },
]);

const departments = allPositions.value.map((position) => position.title);
const locations = ["Abuja"];

const filteredPositions = computed(() => {
  if (filterBy.value === "") {
    return allPositions.value;
  } else {
    return allPositions.value.filter(
      (position) => position.title === filterBy.value
    );
  }
});

console.log(locations);
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition: grid-template-rows 0.5s ease-out, opacity 0.5s ease-out;
}

.wrapper.is-open {
  grid-template-rows: 1fr;
  opacity: 1;
}
</style>
