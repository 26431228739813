<template>
  <div class="overflow-x-scroll no-scrollbar mb-8">
    <PicturesCarousel
      class="animate-carousel3-mobile md:animate-carousel3 image-carousel motion-reduce:animate-none"
      :images="images"
    />
  </div>
</template>

<script setup>
import PicturesCarousel from "./PicturesCarousel.vue";
import image1 from "@/assets/images/picture-7.png";
import image2 from "@/assets/images/picture-8.png";
import image3 from "@/assets/images/picture-9.png";
import image4 from "@/assets/images/picture-10.png";
import image5 from "@/assets/images/picture-11.png";
import image6 from "@/assets/images/picture-12.png";

const images = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
  {
    image: image5,
  },
  {
    image: image6,
  },
];
</script>
