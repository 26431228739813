<template>
  <section>
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <form
        @submit.prevent="handleSubmit"
        class="max-w-[1090px] mx-auto flex flex-col gap-16 md:gap-20 text-sm md:text-xl"
      >
        <div>
          <h2 class="text-base md:text-[2rem] font-bold mb-8 md:mb-12">
            Personal Information
          </h2>

          <div class="flex flex-col gap-14">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="fullName"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >Full Name</label
                >
                <input
                  id="fullName"
                  type="text"
                  placeholder="Adenike Ademola Tolulope"
                  class="bg-AquaHaze w-full text-base placeholder:text-AshGrey py-[14px] px-5 outline-none"
                  required
                  v-model="state.fullName"
                />
              </div>

              <div>
                <label
                  for="phone"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >Phone Number (+234)</label
                >
                <input
                  id="phone"
                  type="number"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.number"
                />
              </div>
            </div>

            <div>
              <p
                for="amount"
                class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-4"
              >
                Donate Amount
              </p>

              <div class="flex flex-col lg:flex-row justify-between gap-8">
                <div class="flex items-center gap-3">
                  <input
                    name="donationAmount"
                    type="radio"
                    id="10000000"
                    value="10000000"
                    class="bg-GreyGoose border-4 border-StarDust accent-GreenHaze"
                    v-model="donationAmount1"
                  />
                  <label for="10000000" class="text-sm md:text-xl"
                    >N10,000,000</label
                  >
                </div>

                <div class="flex items-center gap-3">
                  <input
                    name="donationAmount"
                    type="radio"
                    id="5000000"
                    value="5000000"
                    class="bg-GreyGoose border-4 border-StarDust accent-GreenHaze"
                    v-model="donationAmount1"
                  />
                  <label for="5000000" class="text-sm md:text-xl"
                    >N5,000,000</label
                  >
                </div>

                <div class="flex items-center gap-3">
                  <input
                    name="donationAmount"
                    type="radio"
                    id="2000000"
                    value="2000000"
                    class="bg-GreyGoose border-4 border-StarDust accent-GreenHaze"
                    v-model="donationAmount1"
                  />
                  <label for="2000000" class="text-sm md:text-xl"
                    >N2,000,000</label
                  >
                </div>

                <div class="flex items-center gap-3">
                  <input
                    name="donationAmount"
                    type="radio"
                    id="1000000"
                    value="1000000"
                    class="bg-GreyGoose border-4 border-StarDust accent-GreenHaze"
                    v-model="donationAmount1"
                  />
                  <label for="1000000" class="text-sm md:text-xl"
                    >N1,000,000</label
                  >
                </div>

                <div class="flex-1 flex items-center gap-3">
                  <label for="other" class="text-xl">Other:</label>
                  <input
                    type="number"
                    id="other"
                    value="Amount"
                    class="bg-AquaHaze w-full text-base placeholder:text-DavyGrey py-[14px] px-5 outline-none"
                    v-model="donationAmount2"
                  />
                </div>
              </div>
              <p
                v-if="selectDonationAmount"
                class="text-red-400 text-sm md:text-base mt-2"
              >
                Please select amount
              </p>
            </div>
          </div>
        </div>

        <div>
          <h2 class="text-base md:text-[2rem] font-bold mb-8 md:mb-12">
            Billing Information
          </h2>

          <div class="flex flex-col gap-6">
            <div>
              <label
                for="email"
                class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                >Email Address</label
              >
              <input
                id="email"
                type="email"
                placeholder="adenikeademola@gmail.com"
                class="bg-AquaHaze w-full text-base placeholder:text-AshGrey py-[14px] px-5 outline-none"
                required
                v-model="state.email"
              />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="address"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >Residential Address</label
                >
                <input
                  id="address"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.street"
                />
              </div>

              <div>
                <label
                  for="state"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >State</label
                >
                <input
                  id="state"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.state"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="city"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >City</label
                >
                <input
                  id="city"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.city"
                />
              </div>

              <div>
                <label
                  for="country"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >Country</label
                >
                <input
                  id="country"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.country"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="secondaryNumber"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >Alternate Phone Number (optional)</label
                >
                <input
                  id="secondaryNumber"
                  type="number"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  v-model="state.secondaryNumber"
                />
              </div>

              <div>
                <label
                  for="donation-cause"
                  class="inline-block text-Dune text-sm md:text-xl font-basisGrotesquePro-semibold mb-3"
                  >Which cause would you like to donate to?</label
                >
                <select
                  id="donation-cause"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.donationCause"
                >
                  <option
                    v-for="(item, index) in donationCauses"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center md:text-left">
          <button
            v-if="showPaystackBtn"
            type="submit"
            class="bg-GreenHaze p-4 md:py-5 md:px-[26px] rounded-[40px] text-white text-sm md:text-xl font-bold"
          >
            Donate
          </button>
          <Paystack
            v-else
            :amount="Number(state.donationAmount) * 100"
            :email="state.email"
            :paystackkey="PUBLIC_KEY"
            :reference="reference"
            :callback="processPayment"
            :close="close"
            class="bg-GreenHaze p-4 md:py-5 md:px-[26px] rounded-[40px] text-white text-sm md:text-xl font-bold"
            type="button"
          >
            Donate
          </Paystack>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import Paystack from "vue-paystack/src/index";
import { computed, reactive, ref, watch } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const url = `${process.env.VUE_APP_BASE_URL}/donation`;
const PUBLIC_KEY = process.env.VUE_APP_PAYSTACK_PUBLIC_KEY;

const reference = new Date().getTime().toString();

const processPayment = async () => {
  console.log("processPayment");
  await submitDonationDetails();
};

const close = () => {
  console.log("Payment closed");
};

const donationCauses = [
  "Rights & Equity",
  "Opportunities for Children, Women and Men",
  "Teaching and Education Advancement",
  "Resourceful Agriculture and Nutrition",
  "Essential Infrastructure and Water",
  "Literacy in Digital Skills",
  "Innovation through Technology",
  "Empowerment through Skills Development",
  "Foundation for Sustainable Development",
];

const donationAmount1 = ref("");
const donationAmount2 = ref("");
const state = reactive({
  fullName: "",
  number: "",
  donationAmount: "",
  email: "",
  street: "",
  state: "",
  city: "",
  country: "",
  secondaryNumber: "",
  donationCause: "",
});

watch(donationAmount1, () => {
  state.donationAmount = donationAmount1.value;
});

watch(donationAmount2, () => {
  state.donationAmount = donationAmount2.value;
});

const selectDonationAmount = ref(false);

const showPaystackBtn = computed(
  () =>
    !state.donationAmount ||
    !state.fullName ||
    !state.number ||
    !state.email ||
    !state.street ||
    !state.state ||
    !state.city ||
    !state.country ||
    !state.donationCause
);

const handleSubmit = () => {
  if (!state.donationAmount) {
    selectDonationAmount.value = true;
    toast.info("Select donation amount", {
      autoClose: 2000,
    });
  }
};

const submitDonationDetails = async () => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      full_name: state.fullName,
      number: state.number,
      donation_amount: state.donationAmount,
      email: state.email,
      street: state.street,
      state: state.state,
      city: state.city,
      country: state.country,
      secondary_number: state.secondaryNumber,
      donation_cause: state.donationCause,
    }),
  };
  try {
    const res = await fetch(url, options);
    if (!res.ok) {
      throw new Error("Error while making donation");
    } else {
      toast.success("Donation successful", {
        autoClose: 2000,
      });
    }
  } catch (error) {
    console.log("Error:", error);
    toast.error("Something went wrong", {
      autoClose: 2000,
    });
  }
};
</script>
