import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ProjectsView from "../views/ProjectsView.vue";
import RootAndReliefView from "../views/RootAndReliefView.vue";
import RootAndReliefOutreachView from "../views/RootAndReliefOutreachView.vue";
import ContactUsView from "../views/ContactUsView.vue";
import VolunteerView from "../views/VolunteerView.vue";
import PartnerWithUsView from "../views/PartnerWithUsView.vue";
import MediaGalleryView from "../views/MediaGalleryView.vue";
import MissionAndVisionView from "../views/MissionAndVisionView.vue";
import FAQsView from "../views/FAQsView.vue";
import ApplicationFormView from "../views/ApplicationFormView.vue";
import ExploreOpportunitiesView from "../views/exploreOpportunities/ExploreOpportunitiesView.vue";
import OpportunitiesView from "../views/exploreOpportunities/OpportunitiesView.vue";
import GrantWriterView from "../views/exploreOpportunities/GrantWriterView.vue";
import FundraisingManagerView from "../views/exploreOpportunities/FundraisingManagerView.vue";
import VolunteerCoordinatorView from "../views/exploreOpportunities/VolunteerCoordinatorView.vue";
import VolunteerPledgeView from "../views/VolunteerPledgeView.vue";
import SuccessStoriesView from "../views/SuccessStoriesView.vue";
import DonateView from "../views/DonateView.vue";
import NotFoundView from "../views/NotFoundView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home - Turbham Foundation",
      description:
        "Rooted In Relief. Our foundation is committed to understanding the unique complexities of societal issues in Nigeria. Through in-depth research and engagement, we aim to identify the root causes that contribute to challenges faced by various communities across the country.",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/about-us",
    name: "aboutUs",
    component: AboutView,
    meta: {
      title: "About Us - Turbham Foundation",
      description:
        "Journey through our history, understanding the evolution of Turbham Foundation and the milestones that have shaped our commitment to positive change.",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsView,
    meta: {
      title: "Projects - Turbham Foundation",
      description: "Stay connected with the latest updates",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/projects/diabetic-root-and-relief",
    name: "rootAndReliefProject",
    component: RootAndReliefView,
    meta: {
      title: "Diabetic Root & Relief Project - Turbham Foundation",
      description: "Diabetic Root & Relief Project",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/projects/diabetic-root-and-relief/:id",
    name: "rootAndReliefOutreach",
    component: RootAndReliefOutreachView,
    meta: {
      title: "Diabetic Root & Relief Project - Turbham Foundation",
      description: "Diabetic Root & Relief Project",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: ContactUsView,
    meta: {
      title: "Contact Us - Turbham Foundation",
      description: "Connect with us",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/volunteer",
    name: "volunteer",
    component: VolunteerView,
    meta: {
      title: "Volunteer - Turbham Foundation",
      description: "Join our team of changemakers.",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/partner-with-us",
    name: "partnerWithUs",
    component: PartnerWithUsView,
    meta: {
      title: "Partner With Us - Turbham Foundation",
      description: "Empowering Change through Collaboration",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/media-gallery",
    name: "mediaGallery",
    component: MediaGalleryView,
    meta: {
      title: "Media Gallery - Turbham Foundation",
      description: "Behind the Scenes: Our Impact Unveiled",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/mission-and-vision",
    name: "missionAndVision",
    component: MissionAndVisionView,
    meta: {
      title: "Our Mission And Vision - Turbham Foundation",
      description:
        "Rooting for Equity, Empowerment, and Lifelong Improvements in Nigeria, the Turbham Foundation champions a comprehensive approach to societal upliftment. Envisioning a Nigeria where each individual's potential can take root and flourish, we aim to provide relief through systemic change.",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/faqs",
    name: "faqs",
    component: FAQsView,
    meta: {
      title: "FAQs - Turbham Foundation",
      description:
        "Get answers to common questions about Turbham Foundation and our initiatives.",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/application-form",
    name: "applicationForm",
    component: ApplicationFormView,
    meta: {
      title: "Volunteer Application Form - Turbham Foundation",
      description: "Volunteer Application Form",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/opportunities",
    name: "exploreOpportunities",
    component: ExploreOpportunitiesView,
    meta: {
      title: "Explore Opportunities - Turbham Foundation",
      description:
        "Browse through our available volunteer positions to discover a role that complements your qualifications and areas of interest.",
      toTop: true,
      smoothScroll: true,
    },
    children: [
      {
        path: "",
        name: "opportunities",
        component: OpportunitiesView,
      },
      {
        path: "grant-writer",
        name: "grantWriter",
        component: GrantWriterView,
      },
      {
        path: "fundraising-development-manager",
        name: "fundraisingManager",
        component: FundraisingManagerView,
      },
      {
        path: "volunteer-coordinator",
        name: "volunteerCoordinator",
        component: VolunteerCoordinatorView,
      },
    ],
  },
  {
    path: "/volunteer-pledge",
    name: "volunteerPledge",
    component: VolunteerPledgeView,
    meta: {
      title: "Volunteer Pledge - Turbham Foundation",
      description: "Volunteer Service Pledge",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/success-stories",
    name: "successStories",
    component: SuccessStoriesView,
    meta: {
      title: "Success Stories - Turbham Foundation",
      description: "Success stories",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/donate",
    name: "donate",
    component: DonateView,
    meta: {
      title: "Donate Now - Turbham Foundation",
      description:
        "Join hands with us to make a difference in the lives of those in need – your donation can bring hope, relief, and positive change to communities around the world.",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: NotFoundView,
    meta: {
      title: "Page Not Found | Turbham Foundation",
      toTop: true,
      smoothScroll: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {};
    if (to.meta.toTop) scroll.top = 0;
    return scroll;
  },
});

function removeExistingMetaDescription() {
  const existingMetaDescription = document.querySelector(
    'meta[name="description"]'
  );
  if (existingMetaDescription) {
    existingMetaDescription.parentNode.removeChild(existingMetaDescription);
  }
}
function addMetaDescription(description) {
  const metaTag = document.createElement("meta");
  metaTag.name = "description";
  metaTag.content = description;
  document.head.appendChild(metaTag);
}
router.beforeEach((to, _, next) => {
  document.title = to.meta.title;
  removeExistingMetaDescription();
  addMetaDescription(to.meta.description);
  next();
});

export default router;
