<template>
  <header class="absolute top-9 md:top-14 left-0 w-full z-10">
    <nav class="container px-4 md:px-6 flex justify-between items-center">
      <router-link to="/">
        <img
          src="../assets/svgs/TF-logo.svg"
          alt="Turbham Foundation logo"
          class="w-[147px] md:w-[180px]"
        />
      </router-link>

      <div
        class="hidden md:flex gap-4 lg:gap-[18px] font-basisGrotesquePro text-base"
      >
        <router-link
          v-for="(route, index) in headerRoutes"
          :key="index"
          :to="route.path"
          class="px-2 lg:px-4 hover:text-IrishGreen"
          >{{ route.text }}</router-link
        >
      </div>

      <router-link
        v-if="route.name !== 'donate'"
        to="/donate"
        class="hidden md:block bg-GreenHaze py-[14px] px-5 lg:px-[26px] rounded-[40px] text-white text-base text-center font-basisGrotesquePro-medium"
      >
        Donate Now
      </router-link>

      <div v-else></div>

      <button class="md:hidden" @click="openSidebar">
        <span
          class="inline-block w-8 h-0.5 bg-Dune relative -top-1 before:absolute before:w-full before:h-full before:bg-Dune before:left-0 before:-top-2 after:absolute after:w-full after:h-full after:bg-Dune after:left-0 after:-bottom-2"
        ></span>
      </button>
    </nav>
  </header>
</template>

<script setup>
import { inject } from "vue";
import { useRoute } from "vue-router";
import useRoutes from "@/composables/useRoutes";

const route = useRoute();
const { headerRoutes } = useRoutes();

const showSidebar = inject("showSidebar");
const openSidebar = () => (showSidebar.value = true);
</script>
