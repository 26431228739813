<template>
  <section class="min-h-screen">
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <div class="text-center mb-28">
        <h2
          class="font-libreBaskerville font-bold max-w-[1158px] mx-auto text-RangoonGreen text-[2rem] md:text-5xl lg:text-[5.5rem] mb-14"
        >
          Why Al<span
            class="relative after:content-['\00a0'] after:absolute after:top-2 md:after:top-3 lg:after:top-5 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-2 after:h-2 md:after:w-3 md:after:h-3 lg:after:w-5 lg:after:h-5 after:bg-GreenHaze after:rounded-full"
            >i</span
          >gn with Turbham
          <span class="inline-flex items-center"
            >F<ColoredCircle
              class="bg-HalloweenOrange w-[22px] h-[22px] md:w-7 md:h-7 lg:w-[53px] lg:h-[53px] mt-1"
            />undation?</span
          >
        </h2>
        <p
          class="max-w-[879px] mx-auto text-DavyGrey text-sm md:text-[1.375rem]"
        >
          Exploring the wide-ranging advantages of collaborating with our
          foundation for meaningful and sustainable change.
        </p>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-7"
      >
        <Card
          v-for="(card, index) in cardsInfo"
          :key="index"
          :cardDetails="card"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "@/components/ui/ColoredCircle.vue";
import Card from "@/components/volunteer/Card.vue";
import image1 from "@/assets/images/hands-put-together.png";
import image2 from "@/assets/images/hand-holding-a-glowing-ball.png";
import image3 from "@/assets/images/hand-shake.png";

const cardsInfo = [
  {
    image: image1,
    title: "Shared Values",
    description:
      "Partner with an organization that echoes your commitment to social responsibility and is dedicated to making a tangible, positive impact.",
  },
  {
    image: image2,
    title: "Visibility",
    description:
      "Garner recognition and visibility through our collaborative initiatives and events, showcasing your brand alongside a cause that truly matters.",
  },
  {
    image: image3,
    title: "Customized Partnerships",
    description:
      "Enjoy tailored partnerships that align with the unique goals and objectives of our collaborators, ensuring mutual success in our shared mission.",
  },
];
</script>
