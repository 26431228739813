<template>
  <section class="min-h-screen">
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <div class="text-center mb-[60px] md:mb-20">
        <h2
          class="font-libreBaskerville font-bold text-[2rem] md:text-5xl lg:text-[6.25rem] mb-7 md:mb-[60px]"
        >
          Pr<ColoredCircle
            class="bg-HalloweenOrange w-6 h-6 lg:w-[60px] lg:h-[60px] mx-0.5"
          /><span
            class="text-Dune relative after:content-['\00a0'] after:absolute after:top-2 md:after:top-3 lg:after:top-5 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-2 after:h-2 md:after:w-3 md:after:h-3 lg:after:w-5 lg:after:h-5 after:bg-GreenHaze after:rounded-full"
            >j</span
          >ect Updates
        </h2>
        <p
          class="md:max-w-[674px] mx-auto text-DavyGrey text-base md:text-lg lg:text-[1.375rem]"
        >
          Stay connected with the latest updates on our ongoing projects. Follow
          the progress and witness the positive changes taking place.
        </p>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-7"
      >
        <Card
          v-for="(item, index) in cardsInfo"
          :key="index"
          :cardDetails="item"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "@/components/ui/ColoredCircle.vue";
import Card from "./Card.vue";
import image1 from "@/assets/images/project-updates-1.png";
import image2 from "@/assets/images/project-updates-2.png";
import image3 from "@/assets/images/project-updates-3.png";

const cardsInfo = [
  {
    image: image1,
    title: "Diabetic Root & Relief Project",
    description:
      "The Diabetic Root and Relief Project by Turbham Foundation is a community-focused initiative in Nigeria aimed at promoting awareness and education on diabetes.",
    status: "active",
    path: "/projects/diabetic-root-and-relief",
  },
  {
    image: image2,
    title: "Root & Relief Spellexcel Scholar Support Project",
    description:
      'The "Root and Relief SpellExcel Scholar Support Project" is a comprehensive initiative designed to foster educational excellence, particularly in the realm of spelling.',
    status: "scheduled",
    path: "/projects/diabetic-root-and-relief/spellexcel-scholar-support",
  },
  {
    image: image3,
    title: "Rural Roots Agriculture and Nutrition Project",
    description:
      'The Rural Roots Health Initiative: "Sowing Seeds for Healthy Living" is a comprehensive outreach program designed to foster well-being in rural communities.',
    status: "scheduled",
    path: "/projects/diabetic-root-and-relief/rural-roots-agriculture-and-nutrition",
  },
];
</script>
