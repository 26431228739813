import image1 from "@/assets/images/spelling-bee-outreach.png";
import image2 from "@/assets/images/diabetes-walk-outreach.png";
import image3 from "@/assets/images/bwari-lga-outreach.png";
import image4 from "@/assets/images/yoga-diabetes-outreach.png";
import image5 from "@/assets/images/deidei-community-outreach.png";

export const allProjects = [
  {
    id: "diabetic-root-and-relief",
    title: "Diabetic Root & Relief Project",
    paragraph:
      "Through free testing, the project seeks to empower individuals to know their sugar levels and provide essential information for effective diabetes management. By addressing the root causes and offering relief through education and testing, the project aims to make a positive impact on diabetes awareness and care throughout Nigerian communities.",
    programs: [
      {
        image: image1,
        title: "Diabetes Root and Family Spelling Bee Outreach",
        campaign: "Spelling out a healthier tomorrow",
        date: "November 11, 2023",
      },
      {
        id: "know-your-blood-sugar",
        image: image2,
        title: "Diabetes Walk Outreach (Asokoro LGA Abuja)",
        campaign: "Know your blood sugar",
        date: "November 25, 2023",
      },
      {
        image: image3,
        title: "Bwari LGA Outreach (Zhiko PHC Abuja)",
        campaign: "Know your blood sugar",
        date: "November 30, 2023",
      },
      {
        image: image4,
        title: "Yoga and Diabetes Outreach (Maitama LGA Abuja)",
        campaign: "Diabetes and exercise",
        date: "December 12, 2023",
      },
      {
        image: image5,
        title: "Deidei Community Outreach",
        campaign: "Know your blood sugar",
        date: "January 19, 2024",
      },
    ],
    outreaches: [
      {
        id: "know-your-blood-sugar",
        title:
          'Root & Relief Diabetic Walk and “Know Your Blood Sugar" Guzape Abuja',
        paragraph:
          'This summary report aims to provide an overview of the key metrics, observations, and challenges faced during the Root & Relief Diabetic Walk and “Know Your  Blood Sugar" medical outreach event held on NOV 25 2023 at Asokoro Lga Abuja Nigeria. The information presented herein serves as a basis for future planning and improvement of similar initiatives.',
        overview: {
          status: "active",
          date: "November 25, 2023",
          location: "Guzape Abuja (Asokoro LGA) Abuja",
          ageFocus: "Health Check: >=18 Years",
        },
        description: [
          'Turbham Foundation conducted a medical outreach event on 25th November 2023 at NAFCON Center Abuja Nigeria. The Diabetic  ROOT & RELIEF DIABETIC WALK AND “KNOW YOUR BLOOD SUGAR" program was executed by the Turbham Foundation in collaboration with Turbomedics. The objective of the event was to provide healthcare services to the community and raise awareness about essential health practices.',
          "With community-focus in mind, Turbham Foundation teamed up with top healthcare technology startup Turbomedics to launch a comprehensive Diabetes Awareness and Free Testing Campaign. This cooperative endeavor sought to address the rising incidence of diabetes-related fatalities as well as the deficiency of adequate data to address the underlying reasons.",
        ],
        document:
          "https://drive.google.com/uc?export=view&id=1f6p3jCKOYUlqKs63gxGL_VAluX5Sbfon",
      },
      {
        id: "medical-outreach",
        title: "Root & Relief Medical Outreach",
        paragraph:
          "This summary report aims to provide an overview of the key metrics, observations, and challenges faced during the ROOT & RELIEF medical outreach event held on NOV 24 2023 at Zhiko PHC. The information presented herein serves as a basis for future planning and improvement of similar initiatives.",
        overview: {
          status: "active",
          date: "November 24, 2023",
          location: "Zhiko Primary Health Clinic Abuja",
          ageFocus: ">=18 Years",
        },
        description: [
          "Turbham Foundation conducted a medical outreach event on 24th November 2023 at Zhiko Primary Health Clinic Abuja. The event Titled Diabetic ROOT & RELIEF program was done in partnership with Turbomedics. The objective of the event was to provide healthcare services to the community and raise awareness about essential health practices.",
          "With community-focus in mind, Turbham Foundation teamed up with top healthcare technology startup Turbomedics to launch a comprehensive Diabetes Awareness and Free Testing Campaign. This cooperative endeavor sought to address the rising incidence of diabetes-related fatalities as well as the deficiency of adequate data to address the underlying reasons.",
        ],
        document:
          "https://drive.google.com/uc?export=view&id=1ohkZnNmk_a0Lg7IXKyuOiYeLJzYb15ne",
      },
      {
        id: "spelling-bee-outreach",
        title: "Root & Relief Spelling Bee General Outreach",
        paragraph:
          "A comprehensive initiative designed to foster educational excellence, particularly in the realm of spelling, across a diverse array of schools. This project is rooted in the belief that spelling proficiency serves as a fundamental building block for overall academic success.",
        overview: {
          status: "active",
          date: "November 11, 2023",
          location: "Nigerian Airforce Conference Center Abuja",
          ageFocus: "Spelling Bee: Age 7 - 12 Years, Health Check: >=18 Years",
        },
        description: [
          "Turbham Foundation conducted a medical outreach event on 11th November 2023 at NAFCON Center Abuja Nigeria. The Diabetic ROOT & RELIEF SPELLING BEE program was executed by the Turbham Foundation in collaboration with Turbomedics and Nessa Care Foundation, as part of a jointly hosted Spelling Bee Event. The objective of the event was to provide healthcare services to the community and raise awareness about essential health practices.",
          "With community-focus in mind, Turbham Foundation teamed up with top healthcare technology startup Turbomedics to launch a comprehensive Diabetes Awareness and Free Testing Campaign. This cooperative endeavor sought to encourage Children Education as well as address the rising incidence of diabetes-related fatalities as well as the deficiency of adequate data to address the underlying reasons.",
        ],
      },
      {
        id: "spellexcel-scholar-support",
        title: "Root & Relief Spellexcel Scholar Support Project",
        overview: {
          status: "scheduled",
        },
        description: [
          'Empowering Minds Through Education The "Root and Relief SpellExcel Scholar Support Project" stands as a visionary initiative by Turbham Foundation, dedicated to cultivating educational excellence, with a specific focus on enhancing spelling proficiency in various schools. This comprehensive project is grounded in the firm belief that mastering spelling is a foundational element crucial for overall academic success.',
        ],
        objectives:
          "The primary objective of the project is to empower students with the tools and skills necessary to excel academically, recognizing that a strong foundation in spelling contributes significantly to linguistic competence and cognitive development.",
        projectComponents: [
          {
            title: "Spelling Proficiency Workshops:",
            texts: [
              "The project conducts interactive and engaging spelling proficiency workshops in participating schools.",
              "Employs innovative teaching methods to make learning enjoyable and effective.",
            ],
          },
          {
            title: "Resource Development:",
            texts: [
              "Develops educational resources, including spelling guides, practice materials, and interactive tools.",
              "Distributes these resources to schools to support ongoing learning.",
            ],
          },
          {
            title: "Competitions and Recognition:",
            texts: [
              "Organizes spelling competitions to motivate and inspire students. Recognizes and rewards exceptional spelling achievements, fostering a culture of academic excellence.",
            ],
          },
          {
            title: "Teacher Training Programs:",
            texts: [
              "Conducts training programs for educators to enhance their ability to teach spelling effectively. Facilitates the adoption of modern teaching methodologies in spelling education.",
            ],
          },
        ],
      },
      {
        id: "rural-roots-agriculture-and-nutrition",
        title:
          "Rural Roots Agriculture and Nutrition Project: Sowing Seeds and Healthy Living",
        overview: {
          status: "scheduled",
        },
        description: [
          'The Rural Roots Health Initiative: "Sowing Seeds for Healthy Living" is a comprehensive outreach program designed to foster well-being in rural communities. Recognizing the vital connection between agriculture, hygiene, and nutrition, this initiative aims to plant the seeds for healthier lifestyles and sustainable practices.',
        ],
      },
    ],
  },
  {
    id: "yoga-outreach",
    title: "Yoga Outreach",
    paragraph:
      "Read inspiring success stories that highlight the real-world changes brought about by Turbham Foundation. Be a part of the stories of transformation.",
    programs: [
      {
        image: image1,
        title: "Diabetes Root and Family Spelling Bee Outreach",
        campaign: "Spelling out a healthier tomorrow",
        date: "November 11, 2023",
      },
      {
        image: image2,
        title: "Diabetes Walk Outreach (Asokoro LGA Abuja)",
        campaign: "Know your blood sugar",
        date: "November 25, 2023",
      },
      {
        image: image3,
        title: "Bwari LGA Outreach (Zhiko PHC Abuja)",
        campaign: "Know your blood sugar",
        date: "November 30, 2023",
      },
      {
        image: image4,
        title: "Yoga and Diabetes Outreach (Maitama LGA Abuja)",
        campaign: "Diabetes and exercise",
        date: "December 12, 2023",
      },
      {
        image: image5,
        title: "Deidei Community Outreach",
        campaign: "Know your blood sugar",
        date: "January 19, 2024",
      },
    ],
  },
  {
    id: "refresh-outreach",
    title: "Refresh Outreach",
    paragraph:
      "Through free testing, the project seeks to empower individuals to know their sugar levels and provide essential information for effective diabetes management. By addressing the root causes and offering relief through education and testing, the project aims to make a positive impact on diabetes awareness and care throughout Nigerian communities.",
    programs: [
      {
        image: image1,
        title: "Diabetes Root and Family Spelling Bee Outreach",
        campaign: "Spelling out a healthier tomorrow",
        date: "November 11, 2023",
      },
      {
        image: image2,
        title: "Diabetes Walk Outreach (Asokoro LGA Abuja)",
        campaign: "Know your blood sugar",
        date: "November 25, 2023",
      },
      {
        image: image3,
        title: "Bwari LGA Outreach (Zhiko PHC Abuja)",
        campaign: "Know your blood sugar",
        date: "November 30, 2023",
      },
      {
        image: image4,
        title: "Yoga and Diabetes Outreach (Maitama LGA Abuja)",
        campaign: "Diabetes and exercise",
        date: "December 12, 2023",
      },
      {
        image: image5,
        title: "Deidei Community Outreach",
        campaign: "Know your blood sugar",
        date: "January 19, 2024",
      },
    ],
  },
];
