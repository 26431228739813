<template>
  <section class="relative">
    <div class="hidden lg:block absolute top-0 left-0">
      <img
        src="../../assets/svgs/2-edged-orange-semi-curve.svg"
        alt="orange curve"
      />
    </div>
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <div class="flex flex-col gap-16">
        <Card
          v-for="(card, index) in cardsInfo"
          :key="index"
          :index="index"
          :cardDetails="card"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import Card from "./Card.vue";
import image1 from "@/assets/images/abdul-salam.png";
import image2 from "@/assets/images/omolara.png";
import image3 from "@/assets/images/musa.png";
import image4 from "@/assets/images/madam-tanko.png";
import image5 from "@/assets/images/fatima.png";
import image6 from "@/assets/images/yusuf.png";
import image7 from "@/assets/images/adamu.png";
import image8 from "@/assets/images/ahmed.png";
import image9 from "@/assets/images/sarah-2.png";
import image10 from "@/assets/images/halima-2.png";
import image11 from "@/assets/images/sani.png";
import image12 from "@/assets/images/second-fatima.png";
import image13 from "@/assets/images/mariam-2.png";
import image14 from "@/assets/images/rahmat-2.png";

const cardsInfo = [
  {
    image: image1,
    name: "Abdul Salam",
    bio: [
      "A farmer in the rural area of Bwari LGA, discovered that he suffers from high blood pressure during Turbham Foundation's outreach, enabling him to seek timely medical intervention and transform his health.",
    ],
  },
  {
    image: image2,
    name: "Omolara",
    bio: [
      "At the Turbham Foundations initiative in Asokoro LGA, we uncovered Omolara's journey, where she bravely faced elevated blood glucose levels. Witnessing her transformative lifestyle changes was not just inspiring but downright captivating.",
      "Omolara's resilience not only enhanced her well-being but radiated a positive influence, creating a ripple effect of health and vitality within her family. It's a story of redemption and empowerment that leaves you truly moved.",
    ],
  },
  {
    image: image3,
    name: "Musa",
    bio: [
      "Through the Turbham Foundation's impactful outreach, we delve into Musa's narrative – a dedicated driver who, through this initiative, discovered the truth about his high BMI. What follows is a captivating tale of personal metamorphosis.",
      "Fueled by this awareness, Musa didn't merely adopt a healthier diet and exercise routine; he embarked on a profound journey of self-transformation.",
      "The result? A visually striking story of significant weight loss and a remarkable surge in vitality. Musa's remarkable journey is a testament to the potent magic that resides in the simplicity of lifestyle changes, redefining not just his physique but the essence of his well-being.",
    ],
  },
  {
    image: image4,
    name: "Madam Tanko",
    bio: [
      "In the heart of Zhiko community, the Turbham Foundation's Root & Relief program went beyond identifying Madam Tanko's diabetes; it became a catalyst for empowerment. This initiative not only shed light on her health condition but also provided crucial education on its proper management.",
      "The result was not just a management plan but a newfound empowerment that allowed Madam Tanko to embrace a more active and fulfilling life. Turbham Foundation's impact goes beyond diagnosis – it's about transforming lives and fostering a renewed sense of control and vitality.",
    ],
  },
  {
    image: image5,
    name: "Fatima",
    bio: [
      "During a health screening organized by Turbham Foundation, Fatima, a talented seamstress, was confronted with the revelation of elevated blood pressure.",
      "This pivotal moment didn't deter her; instead, it ignited a journey of empowerment. Determined to prioritize her health, Fatima committed to a heart-healthy lifestyle, seamlessly incorporating it into her daily routine.",
      "What makes this story truly compelling is the ripple effect she unintentionally created. Her newfound dedication to well-being inspired not just personal change but radiated through her sewing studio, prompting clients to reevaluate and prioritize their health.",
      " In the fabric of everyday life, Fatima's tale weaves authenticity and inspiration, showcasing the tangible impact of proactive health choices.",
    ],
  },
  {
    image: image6,
    name: "Yusuf",
    bio: [
      "In Turbham Foundation's community outreach, Yusuf's health journey unfolded organically. His elevated blood glucose levels became a turning point, compelling him to take charge of his well-being.",
      "Yusuf's commitment extended beyond just tweaking his diet and adopting an exercise routine; it became a genuine lifestyle shift that effectively reversed his prediabetic condition, resulting in a tangible improvement in his overall health.",
      "But Yusuf's story doesn't end there—it takes on a richer dimension. Fueled by a desire to give back, he now actively engages with the community, leading Health exercises and incorporating Yoga poses learned from the Yoga and Diabetes Workshop.",
      "The Turbham Foundation's impact goes beyond diagnosis; it's about fostering real, sustainable transformations that resonate and uplift.",
    ],
  },
  {
    image: image7,
    name: "Adamu",
    bio: [
      "In the course of Turbham Foundation's medical outreach in Adamu's village, located on the outskirts of Abuja, his vitals were meticulously examined, revealing the presence of hypertension. Prompt medical intervention and proper treatment were administered, leading to Adamu's complete recovery.",
      "This strategic intervention not only restored his health but also ensured his ability to resume providing for his family in a manner consistent with optimal well-being.",
      "Within the intricate dynamics of livelihood and well-being, Turbham Foundation's initiative stands as a foundational element, fostering the holistic health and resilience of both the individual and the community.",
    ],
  },
  {
    image: image8,
    name: "Ahmed",
    bio: [
      "In the heart of Turbham Foundation's Root and Relief initiative, Ahmed, a revered community leader, found himself facing elevated cholesterol levels. Instead of allowing this revelation to be a personal setback, Ahmed turned it into a rallying cry for positive change within his village.",
      "Rather than keeping this discovery to himself, Ahmed transformed it into a catalyst for change within the village. With fervor and conviction, he started advocating for healthier eating habits and promoting the benefits of regular exercise.",
      "His personal commitment to well-being resonated throughout the community, sparking a collective shift towards healthier lifestyles.",
      "Ahmed's story is not just about an individual health journey; it's a testament to the transformative power of community leadership. The Turbham Foundation's initiative, through Ahmed's advocacy, becomes a beacon for positive change, illustrating how one person's commitment can inspire a broader health revolution within a community.",
    ],
  },
  {
    image: image9,
    name: "Sarah",
    bio: [
      "In Sarah's academic journey, Turbham Foundation's community health screening emerged as a pivotal chapter. Uncovering her elevated BMI at an early stage became a catalyst for a purposeful transformation rather than a setback.",
      "Sarah didn't just respond personally; she seized the opportunity to cultivate a lifestyle defined by healthier habits. What elevates her narrative is the way she transformed this personal commitment into an inspiration for her peers.",
      "Sarah's journey became a source of collective motivation, instigating a positive shift towards well-being within her community.",
      "In the vibrant tapestry of student life, Turbham Foundation's community health screening, through Sarah's narrative, stands out as a beacon of health empowerment. It's not merely about early detection but about igniting a collective spark that radiates through the lives of young individuals, creating a culture where well-being takes center stage.",
    ],
  },
  {
    image: image10,
    name: "Halima",
    bio: [
      "In a critical turn of events, Turbham Foundation's vital testing played a pivotal role in Halima's pregnancy journey. The timely identification of gestational diabetes became a lifeline, averting potential complications and paving the way for a healthier pregnancy and delivery for both mother and baby.",
      "Halima's story is not just a testament to the significance of early detection but also highlights the tangible impact that proactive health measures can have on maternal and child well-being.",
      "Turbham Foundation's role goes beyond testing; it becomes a guardian of health, ensuring a smoother and safer journey for expecting mothers like Halima.",
    ],
  },
  {
    image: image11,
    name: "Sani",
    bio: [
      "A local mechanic, found a significant shift in his approach to health through Turbham Foundation's outreach. The discovery of hypertension wasn't just a diagnosis for him; it became a starting point for positive change.",
      "Rather than letting it weigh him down, Sani chose to prioritize regular check-ups as part of his proactive health routine. What makes Sani's story authentic is how his personal journey naturally influenced his colleagues in the garage.",
      "Through genuine conversations and shared experiences, Sani's commitment to health motivated his fellow mechanics to start paying closer attention to their own well-being.",
      "In the everyday reality of a mechanic's workplace, Turbham Foundation's impact, as seen through Sani's narrative, becomes a relatable tale of personal empowerment and communal awareness. It reflects a realistic shift towards healthier practices, where colleagues inspire one another to prioritize health in a tangible and achievable way.",
    ],
  },
  {
    image: image12,
    name: "Fatima",
    bio: [
      "After undergoing medical testing with Turbham Foundation, Fatima, a market vendor, received unexpected news about her potential risk for hypertension. This revelation hit close to home, stirring a genuine concern for her well-being. Determined to take control of her health, Fatima embarked on a relatable journey.",
      "Instead of adopting drastic measures, she made small, realistic changes to her diet and started incorporating regular physical activity into her busy routine. It wasn't about grand gestures but about weaving healthier choices into the fabric of her daily life.",
      "In the midst of the bustling market, Fatima's story reflects the authenticity of real-life struggles and triumphs. Her journey mirrors the relatable path many individuals tread when faced with health concerns. The Turbham Foundation's role becomes more than just a testing ground; it becomes a source of support, enabling genuine, achievable transformations for people like Fatima.",
    ],
  },
  {
    image: image13,
    name: "Mariam",
    bio: [
      "In a significant moment facilitated by Turbham Foundation's initiative, Mariam, a devoted housewife, discovered her high blood glucose levels. Rather than feeling disheartened, Mariam embraced this revelation as an opportunity for positive transformation.",
      "Proactively taking charge of her health, Mariam introduced mindful changes to her diet and incorporated regular exercise into her routine, effectively managing her diabetes. Yet, Mariam's journey didn't conclude there.",
      "Empowered by her personal experience, she gracefully transitioned into a dedicated health advocate within her community.",
      "Mariam's story, shaped by Turbham Foundation's initiative, showcases the profound impact of individual empowerment. Beyond managing a health condition, it becomes a narrative of resilience and inspiration that resonates within the community. In Mariam's advocacy, Turbham Foundation's initiative stands as a beacon for broader health awareness and positive lifestyle changes.",
    ],
  },
  {
    image: image14,
    name: "Rahmat",
    bio: [
      "In a transformative turn of events, Turbham Foundation's outreach brought to light Rahmat's elevated BMI. Rather than letting this revelation dampen her spirits, Rahmat saw it as an opportunity to spark change within her community.",
      "Motivated by her own journey, Rahmat took the lead by initiating community fitness programs and advocating for healthier eating habits. What unfolded was more than just a personal transformation; it became a catalyst for positive change within the entire village.",
      "Rahmat's commitment to well-being created a ripple effect, fostering a community-wide shift towards healthier lifestyles.",
      "The Turbham Foundation's outreach, as evidenced through Rahmat's story, showcases the profound impact of empowering individuals to take charge of their health and inspire collective change.",
    ],
  },
];
</script>
