<template>
  <section class="min-h-screen">
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <form
        @submit.prevent="handleSubmit"
        class="max-w-[1090px] mx-auto flex flex-col gap-14 md:gap-20"
      >
        <div>
          <h2 class="text-Dune text-sm md:text-[2rem] font-bold mb-12">
            Personal Information
          </h2>

          <div class="flex flex-col gap-6">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="fullName"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Full Name</label
                >
                <input
                  id="fullName"
                  type="text"
                  placeholder="Adenike Ademola Tolulope"
                  class="bg-AquaHaze w-full text-base placeholder:text-AshGrey py-[14px] px-5 outline-none"
                  required
                  v-model="state.fullName"
                />
              </div>

              <div>
                <label
                  for="dob"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Date Of Birth</label
                >
                <input
                  id="dob"
                  type="date"
                  class="bg-AquaHaze w-full text-base placeholder:text-AshGrey py-[14px] px-5 outline-none"
                  required
                  v-model="state.dob"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2">
              <div>
                <label
                  for="languages"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Spoken Languages</label
                >
                <select
                  id="languages"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.language"
                >
                  <option value="en">English</option>
                </select>
              </div>
            </div>

            <div>
              <label
                for="servicePledge"
                class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                >Have you read the Volunteer Service Pledge?</label
              >
              <select
                id="servicePledge"
                class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                required
                v-model="state.pledge"
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <h2 class="text-Dune text-sm md:text-[2rem] font-bold mb-12">
            Contact Information
          </h2>

          <div class="flex flex-col gap-6">
            <div>
              <label
                for="email"
                class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                >Email Address</label
              >
              <input
                id="email"
                type="email"
                placeholder="adenikeademola@gmail.com"
                class="bg-AquaHaze w-full text-base placeholder:text-AshGrey py-[14px] px-5 outline-none"
                required
                v-model="state.email"
              />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="address"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Residential Address</label
                >
                <input
                  id="address"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.street"
                />
              </div>

              <div>
                <label
                  for="state"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >State</label
                >
                <input
                  id="state"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.state"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="city"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >City</label
                >
                <input
                  id="city"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.city"
                />
              </div>

              <div>
                <label
                  for="country"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Country</label
                >

                <input
                  id="country"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.country"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="phoneNumber"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Phone Number (+234)</label
                >
                <input
                  id="phoneNumber"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.number"
                />
              </div>

              <div>
                <label
                  for="secondaryNumber"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Alternate Phone Number (optional)</label
                >
                <input
                  id="secondaryNumber"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  v-model="state.secondaryNumber"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="instagram"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Instagram Handle</label
                >
                <input
                  id="instagram"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  v-model="state.instagram"
                />
              </div>

              <div>
                <label
                  for="facebook"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Facebook Handle</label
                >
                <input
                  id="facebook"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  v-model="state.facebook"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="twitter"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Twitter Handle</label
                >
                <input
                  id="twitter"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  v-model="state.twitter"
                />
              </div>

              <div>
                <label
                  for="shirtSize"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Shirt Size</label
                >
                <input
                  id="shirtSize"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.shirtSize"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 class="text-Dune text-sm md:text-[2rem] font-bold mb-12">
            Availability
          </h2>
          <div>
            <label
              for="availability"
              class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
              >Are you available for an impromptu mini outreach?</label
            >

            <select
              id="availability"
              class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
              required
              v-model="state.availability"
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
        </div>

        <div>
          <h2 class="text-Dune text-base md:text-[2rem] font-bold mb-12">
            Additional Information
          </h2>
          <div class="flex flex-col gap-6">
            <div>
              <label
                for="occupation"
                class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                >Current Occupation</label
              >

              <input
                id="occupation"
                type="text"
                class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                required
                v-model="state.occupation"
              />
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-12">
              <div>
                <label
                  for="placeOfWork"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Organization/Place of Work</label
                >

                <input
                  id="placeOfWork"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.placeOfWork"
                />
              </div>

              <div>
                <label
                  for="volunteerRole"
                  class="inline-block text-Dune text-sm md:text-xl font-medium mb-[14px]"
                  >Volunteer Role</label
                >

                <input
                  id="volunteerRole"
                  type="text"
                  class="bg-AquaHaze w-full text-base py-[14px] px-5 outline-none"
                  required
                  v-model="state.volunteerRole"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 class="text-Dune text-base md:text-[2rem] font-bold mb-12">
            Volunteering Motivation
          </h2>

          <div class="text-Dune text-xs md:text-xl">
            <div class="flex flex-col gap-[14px] mb-8">
              <p class="text-sm md:text-xl font-basisGrotesquePro-semibold">
                Why do you want to volunteer with Turbham Foundation?
              </p>
              <p class="italic">
                Please provide accurate and up-to-date contact information to
                ensure effective communication. Your privacy is important to us,
                and this information will only be used for volunteer-related
                purposes. Thank you for your attention to detail.
              </p>
            </div>

            <div class="h-[88px]">
              <input
                id="motivation"
                type="text"
                class="bg-AquaHaze w-full h-full text-base px-5 outline-none"
                required
                v-model="state.volunteerMotivation"
              />
            </div>
          </div>
        </div>

        <div class="mt-16 md:mt-44 text-center md:text-left">
          <button
            :disabled="loading"
            class="bg-GreenHaze p-4 md:py-5 md:px-[26px] rounded-[40px] text-white text-sm md:text-2xl font-bold"
          >
            {{ loading ? "Submitting..." : "Submit Volunteer Form" }}
          </button>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const router = useRouter();

const state = reactive({
  fullName: "",
  dob: "",
  language: "",
  pledge: "",
  state: "",
  email: "",
  street: "",
  state: "",
  city: "",
  country: "",
  number: "",
  secondaryNumber: "",
  instagram: "",
  facebook: "",
  twitter: "",
  shirtSize: "",
  availability: "",
  occupation: "",
  placeOfWork: "",
  volunteerRole: "",
  volunteerMotivation: "",
});
const loading = ref(false);

const url = `${process.env.VUE_APP_BASE_URL}/volunteer`;

const handleSubmit = async () => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      full_name: state.fullName,
      date_of_birth: state.dob,
      language: state.language,
      pledge: state.pledge === "true",
      email: state.email,
      number: state.number,
      secondary_number: state.secondaryNumber,
      street: state.street,
      city: state.city,
      state: state.state,
      country: state.country,
      instagram: state.instagram,
      facebook: state.facebook,
      twitter: state.twitter,
      shirt_size: state.shirtSize,
      are_you_available: state.availability === "true",
      occupation: state.occupation,
      place_of_work: state.placeOfWork,
      volunteer_role: state.volunteerRole,
      volunteer_motivation: state.volunteerMotivation,
    }),
  };
  try {
    loading.value = true;
    const res = await fetch(url, options);
    if (!res.ok) {
      throw new Error("Error submitting details");
    } else {
      toast.success("Submitted successfully", {
        autoClose: 2000,
      });
      router.push("/volunteer-pledge");
    }
  } catch (error) {
    console.log("Error:", error);
    toast.error("Something went wrong", {
      autoClose: 2000,
    });
  } finally {
    loading.value = false;
  }
};
</script>
