<template>
  <Header />
  <Sidebar />
  <router-view />
  <Footer />
</template>

<script setup>
import { provide, ref } from "vue";
import Header from "@/components/Header.vue";
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";

const showSidebar = ref(false);

provide("showSidebar", showSidebar);
</script>

<style>
.router-link-active {
  @apply text-IrishGreen;
}
</style>
