<template>
  <section class="min-h-screen">
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <div class="text-center mb-28">
        <h2
          class="font-libreBaskerville font-bold max-w-[1277px] mx-auto text-RangoonGreen text-[2rem] md:text-5xl lg:text-[5.5rem] mb-6"
        >
          Why V<ColoredCircle
            class="bg-HalloweenOrange w-[22px] h-[22px] md:w-7 md:h-7 lg:w-[53px] lg:h-[53px]"
          />lunteer w<span
            class="relative after:content-['\00a0'] after:absolute after:top-2 md:after:top-3 lg:after:top-5 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-2 after:h-2 md:after:w-3 md:after:h-3 lg:after:w-5 lg:after:h-5 after:bg-GreenHaze after:rounded-full"
            >i</span
          >th the Turbham Foundation?
        </h2>
        <p
          class="max-w-[879px] mx-auto text-DavyGrey text-sm md:text-[1.375rem]"
        >
          Stay connected with the latest updates on our ongoing projects. Follow
          the progress and witness the positive changes taking place.
        </p>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-7"
      >
        <Card
          v-for="(card, index) in cardsInfo"
          :key="index"
          :cardDetails="card"
          :btn="false"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "@/components/ui/ColoredCircle.vue";
import Card from "./Card.vue";
import image1 from "@/assets/images/volunteer-with-children.png";
import image2 from "@/assets/images/lady-volunteer.png";
import image3 from "@/assets/images/group-of-volunteers.png";

const cardsInfo = [
  {
    image: image1,
    title: "Meaningful Impact",
    description:
      "Participate in projects and activities that directly benefit communities and individuals in need.",
  },
  {
    image: image2,
    title: "Skill Development",
    description:
      "Working on real-world challenges allows you to develop and improve your talents.",
  },
  {
    image: image3,
    title: "Community",
    description:
      "Join a diverse and supportive group of like-minded people who share your desire to make a difference.",
  },
];
</script>
