<template>
  <section class="min-h-screnn">
    <div class="container px-4 md:px-6 py-20">
      <div class="max-w-[1062px] mx-auto flex flex-col gap-8">
        <div
          v-for="(faq, index) in allFaqs"
          :key="index"
          class="bg-AquaHaze py-[18px] md:py-6 px-8 md:px-14"
        >
          <div
            @click="faq.open = !faq.open"
            class="flex justify-between items-center gap-10 cursor-pointer"
          >
            <p class="font-basisGrotesquePro-semibold text-sm md:text-xl">
              {{ faq.question }}
            </p>
            <img
              src="../../assets/svgs/down-arrow.svg"
              alt="down arrow"
              class="transition-all duration-300"
              :class="faq.open ? 'rotate-180' : 'rotate-0'"
            />
          </div>

          <div
            class="max-w-[732px] text-xs md:text-base wrapper"
            :class="faq.open && faq.answers.length > 0 && 'is-open pt-4'"
          >
            <div class="flex flex-col gap-3 overflow-hidden">
              <p
                v-for="(answer, index) in faq.answers"
                :key="index"
                v-html="answer"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

const allFaqs = ref([
  {
    question: "Can I make an impact at Turhbam Foundation?",
    answers: [
      "Absolutely! Turbham Foundation welcomes individuals and corporate entities who are passionate about making a positive impact on communities. Whether you have skills in healthcare, community development, fundraising, or volunteerism, there are various opportunities for you to contribute. Visit our volunteer or career sections on our website to learn about current opportunities and how you may become involved.",
    ],
    open: false,
  },
  {
    question: "Is it possible to volunteer at Turbham Foundation?",
    answers: [
      "Absolutely! We welcome and appreciate individuals who are passionate about making a positive impact.",
      "You can explore exciting volunteer opportunities at Turbham Foundation by checking our dedicated volunteer page for more details on how to get involved and contribute to our mission.",
    ],
    open: false,
  },
  {
    question: "Does Turbham Foundation have sponsors?",
    answers: [
      `In the spirit of humanity and shared responsibility, Turbham Foundation believes that every act of kindness, no matter how small, has the power to create positive and lasting change.",
      "Turbham Foundation collaborates with sponsors and partners who share our commitment to making a positive impact in communities. ",
      'If you or your organization are interested in becoming a sponsor or partnering with Turbham Foundation, please reach out to us through the <span class='font-basisGrotesquePro-semibold'>"Contact"</span> section on our website, and our team will be happy to discuss potential collaboration opportunities.`,
    ],
    open: false,
  },
  {
    question: "How can I donate to Turbham Foundation?",
    answers: [
      "Thank you for considering a donation to Turbham Foundation! Your support is crucial in helping us carry out our mission.",
      `You can make a donation through our website by visiting the <span class='font-basisGrotesquePro-semibold'>"Donate"</span> section. We accept various forms of contributions, including one-time donations, monthly pledges, and even in-kind donations. Your generosity directly contributes to our initiatives and makes a meaningful impact on the lives we touch.`,
    ],
    open: false,
  },
  {
    question: "What projects is Turbham Foundation currently working on?",
    answers: [
      `Turbham Foundation is actively engaged in several projects aimed at improving healthcare, education, and community well-being. To stay updated on our current projects, visit the <span class='font-basisGrotesquePro-semibold'>"Projects"</span> section on our website. There, you'll find detailed information about each initiative, our progress, and how these projects are positively impacting the communities we serve.`,
    ],
    open: false,
  },
  {
    question: "Is Turbham Foundation actively involved in community outreach?",
    answers: [
      `Yes, community outreach is a core aspect of Turbham Foundation's mission. We actively engage in community outreach programs to address various issues such as healthcare awareness, education, and social welfare. Our goal is to make a positive impact by directly interacting with communities, understanding their needs, and implementing sustainable solutions. Visit the <span class='font-basisGrotesquePro-semibold'>"Community Outreach"</span> section on our website to learn more about our past and ongoing outreach initiatives.`,
    ],
    open: false,
  },
  {
    question: "How can I stay updated on Turbham Foundation's activities?",
    answers: [
      "To stay informed about Turbham Foundation's activities, you can subscribe to our newsletter through our website. Additionally, follow us on social media platforms like Facebook, Twitter, and Instagram for real-time updates, success stories, and upcoming events. Your continued interest and support are crucial in helping us share our mission and impact with a broader audience.",
    ],
    open: false,
  },
  {
    question: "How can I contact the foundation for further inquiries?",
    answers: ["prd@turbhamfoundation.org", "0909 090 0919"],
    open: false,
  },
]);
</script>

<style scoped>
.wrapper {
  display: grid;
  grid-template-rows: 0fr;
  opacity: 0;
  transition: grid-template-rows 0.3s ease-out, opacity 0.3s ease-out;
}

.wrapper.is-open {
  grid-template-rows: 1fr;
  opacity: 1;
}
</style>
