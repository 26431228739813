<template>
  <HeroSection :title="outreach.title" :paragraph="outreach.paragraph" />
  <OverviewAndDescription
    :overview="outreach.overview"
    :description="outreach.description"
    :doc="outreach.document"
    :obj="outreach.objectives"
    :components="outreach.projectComponents"
  />
  <GetInvolved />
</template>

<script setup>
import HeroSection from "@/components/rootAndReliefOutreach/HeroSection.vue";
import OverviewAndDescription from "@/components/rootAndReliefOutreach/OverviewAndDescription.vue";
import GetInvolved from "@/components/GetInvolved.vue";
import { useRoute } from "vue-router";
import { allProjects } from "@/utils/data";

const route = useRoute();
const id = route.params.id;
const project = allProjects.find(
  (project) => project.id === "diabetic-root-and-relief"
);
const outreach = project.outreaches.find((item) => item.id === id);
</script>
