<template>
  <section>
    <div class="container px-4 md:px-6 py-20">
      <div
        class="pt-[75px] px-4 md:px-8 pb-20 border sm:border-2 border-FrenchGrey rounded"
      >
        <div class="mb-24">
          <div
            class="text-Grey text-base md:text-2xl font-basisGrotesquePro-medium mb-20"
          >
            <h2
              class="text-Dune text-xl md:text-[2rem] font-basisGrotesquePro-bold mb-6"
            >
              Fundraising Development Manager
            </h2>
            <div class="flex flex-col gap-5">
              <p class="flex items-center gap-2">
                <img
                  src="../../assets/svgs/location-icon.svg"
                  alt="location icon"
                />
                <span>Turbham Foundation House, Abuja Nigeria.</span>
              </p>
              <p class="flex items-center gap-2">
                <img src="../../assets/svgs/pay-icon.svg" alt="pay icon" />
                <span>
                  3,000,000 NGN to 4,200,000 NGN per Annum(depending on
                  experience)
                </span>
              </p>
              <p class="flex items-center gap-2">
                <img src="../../assets/svgs/clock-light.svg" alt="clock icon" />
                <span>
                  5 days per week (Flexible days of work between Monday to
                  Friday)
                </span>
              </p>
            </div>
          </div>

          <div
            class="text-sm md:text-2xl font-basisGrotesquePro flex flex-col gap-16"
          >
            <div>
              <h3
                class="text-base md:text-2xl font-basisGrotesquePro-bold mb-5"
              >
                About the job
              </h3>
              <p>
                We are seeking a dedicated and passionate individual to join our
                team as a Fundraising Development Manager. As a charity
                foundation committed to making a positive impact, this crucial
                role involves managing and enhancing the human capital that
                drives our mission forward and you are responsible to Director
                of Business Development, Group Communicatiosns and Fundraising.
              </p>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">Responsibilities</h4>

              <ul class="pl-4 md:pl-6 list-decimal flex flex-col gap-6">
                <li>
                  Co-ordination and supervision of interns, volunteers, and
                  other colleagues on specific fundraising projects, as
                  required.
                </li>

                <li>
                  Working closely with the Director of Group Communications,
                  Business Development, and Fundraising, manage and develop all
                  aspects of fundraising at Turbham Foundation. The post holder
                  will be responsible for bringing in circa USD 1.5 million
                  (over the next 3 years) of funding and support from a variety
                  of fundraising disciplines for Turbham Foundation's Research
                  and Engagement program.
                </li>

                <li>
                  Responsibilities include writing cases for support and funding
                  applications, stakeholder and supporter cultivation, and
                  delivery of small fundraising events. Working with the
                  Director, the postholder will also develop and implement a
                  strategy to bring in new income streams.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                Key Result Areas:
              </h4>
              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Prospecting and research for Turbham Foundation’s supporter
                  networks; Patrons and Ambassadors, trusts and foundations, and
                  supporting partners.
                </li>
                <li>
                  Liaise with internal teams regarding their funding needs,
                  gathering content for cases of support and evaluation reports
                  for funders.
                </li>
                <li>
                  Write applications and cases of support to trusts and
                  foundations and proposals to corporate organizations.
                </li>
                <li>
                  Complete evaluation reports for trusts and foundations and
                  supporting partners in line with their reporting structure.
                </li>
                <li>
                  Cultivation, development, and growth of our supporter networks
                  Patrons and Ambassador Programme, Supporting Partners, Trusts,
                  and Foundations.
                </li>
                <li>
                  Write internal reports and presentations on fundraising
                  progress for senior management, the Research and Engagement
                  committee, and the Board.
                </li>
                <li>
                  Manage and develop the Archaeology Ambassador and Patrons
                  Programme.
                </li>
                <li>
                  Prospect for and oversee invitations to new Ambassadors.
                </li>
                <li>
                  Ensure that all Ambassador marketing materials and
                  communication plans are up to date, including; Ambassador
                  newsletters, and other news mail-shots as required.
                </li>
                <li>
                  Oversee annual donations, acceptance and reminders to donors.
                </li>
                <li>
                  Give support to create, manage and deliver event logistics for
                  Ambassador and Patrons events (3-4 small events per year)
                  including; guest lists, invitations, budgets.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                Fundraising event development, management, and delivery
                including:
              </h4>
              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Manage overall logistics for three to four small events per
                  year including; <br />
                  - Appoint and liaise with appropriate venues and suppliers.
                </li>
                <li>
                  Oversee the creation of communications materials, i.e.,
                  invitations, presentations.
                </li>
                <li>
                  Responsibilities include writing cases for support and funding
                  applications, stakeholder and supporter cultivation, and
                  delivery of small fundraising events. Working with the
                  Director, the postholder will also develop and implement a
                  strategy to bring in new income streams.
                </li>
                <li>Manage event budgets and event follow up.</li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                General administration for Fundraising including:
              </h4>
              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Maintaining auditable budget and donations records with the
                  Finance team, writing external and internal reports
                  presentations and letters.
                </li>
                <li>
                  Support communications with the website management for the
                  Turbham Foundation website Support Us pages keeping
                  information current and relevant and manage the
                  support@turbham inbox.
                </li>
                <li>
                  Maintain and update donors and contacts records on Union
                  Square (central CRM).
                </li>
                <li>
                  Support for the quarterly Research & Engagement committee with
                  provision of fundraising progress reports.
                </li>
                <li>
                  Ensure Turbham Foundation’s fundraising activities are
                  compliant with the fundraising regulatory environment.
                </li>
                <li>
                  Ensure we handle donor and personal data in accordance with
                  GDPR.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                Special Circumstances:
              </h4>
              <p>
                Special Circumstances: The role requires some flexibility with
                regard to working hours, including the need to work the
                occasional evening together with the need to occasionally travel
                outside of the Abuja Environs.
              </p>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">Essential</h4>
              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Educated to (relevant) degree level or equivalent experience.
                </li>
                <li>
                  Professional membership of the Institute of Fundraising and/or
                  a strong familiarity with the IoF codes of practice.
                </li>
                <li>
                  Maintain and update donors and contacts records on Union
                  Square (central CRM).
                </li>
                <li>
                  Experience in producing excellent and successful five to
                  six-figure written fundraising applications and developing new
                  and existing fundraising disciplines and supporter networks.
                </li>
                <li>
                  Experience of managing Gift Aid and other financial records.
                </li>
                <li>
                  Experience of working successfully in a fundraising,
                  client-facing team.
                </li>
                <li>Interest in Archaeology and/or Arts and Heritage.</li>
                <li>
                  Experience in budget preparation and monitoring reporting and
                  presentations.
                </li>
                <li>
                  Experience of maintaining client databases (e.g., USQ) CRM.
                </li>
                <li>
                  Extensive experience and familiarity with Microsoft Office
                  including PPT, Word, and Excel.
                </li>
                <li>Digital fundraising experience.</li>
                <li>Experience of organizing a range of small events.</li>
                <li>Knowledge of community or events fundraising.</li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                Skills/Competencies
              </h4>
              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Demonstrated interest and an excellent aptitude for
                  fundraising.
                </li>
                <li>
                  Strategic approach to fundraising and marketing activity
                  development.
                </li>
                <li>Strong interpersonal and networking skills.</li>
                <li>
                  Outstanding organizational abilities, with an aptitude for
                  planning ahead, prioritizing effectively and able to cope with
                  competing demands.
                </li>
                <li>
                  Experience of being a point of contact for external
                  stakeholders.
                </li>
                <li>
                  A personable, professional, self-confident and diplomatic
                  demeanor with outstanding written and spoken English
                  communication skills, able to show careful attention to detail
                  to all written applications and reports.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="font-basisGrotesquePro-semibold">How to Apply:</h4>
              <p>
                To apply, please submit your resume, cover letter, and examples
                of successful grant applications to
                recruiting@turbhamfoundation.org. Turbham Foundation is an equal
                opportunity employer, and we encourage individuals from diverse
                backgrounds to apply.
              </p>
            </div>
          </div>
        </div>

        <div>
          <router-link
            to="#"
            class="font-basisGrotesquePro-bold bg-GreenHaze p-4 md:py-5 md:px-[26px] rounded-[40px] text-white text-base text-center"
          >
            Apply Now
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>
