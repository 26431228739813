<template>
  <div
    :id="cardDetails.path"
    class="border border-LightGrey py-8 px-4 md:p-8 lg:p-16 rounded-2xl cursor-pointer"
  >
    <div
      class="flex flex-col lg:flex-row gap-8 md:gap-16"
      :class="!readMore && 'items-center'"
    >
      <div
        class="w-full lg:max-w-[300px] lg:h-[370px] rounded-[10px] overflow-hidden"
        :class="(index + 1) % 2 === 0 ? 'order-2' : 'order-1'"
      >
        <img :src="cardDetails.image" :alt="cardDetails.name" class="w-full" />
      </div>

      <div
        class="w-full lg:max-w-[665px]"
        :class="(index + 1) % 2 === 0 ? 'order-1' : 'order-2'"
      >
        <h3
          class="font-basisGrotesquePro-bold text-3xl sm:text-4xl lg:text-5xl mb-3 md:mb-6"
        >
          {{ cardDetails.name }}
        </h3>
        <div
          class="text-DavyGrey text-base md:text-[1.375rem] flex flex-col gap-3 md:gap-5"
        >
          <p v-for="(item, index) in slicedBios" :key="index">
            {{ item }}
            <span
              @click="showAll"
              v-if="!readMore && bios.length > 1"
              class="font-basisGrotesquePro-semibold underline"
              >Read More</span
            >
            <span
              @click="collapse"
              v-if="readMore && index + 1 === bios.length"
              class="font-basisGrotesquePro-semibold underline"
              >Collapse</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const { cardDetails } = defineProps({
  index: Number,
  cardDetails: Object,
});

const readMore = ref(false);
const bios = ref(cardDetails.bio);
const paragraphs = ref(1);
const slicedBios = computed(() => bios.value.slice(0, paragraphs.value));
const showAll = () => {
  readMore.value = true;
  paragraphs.value = bios.value.length;
};
const collapse = () => {
  readMore.value = false;
  paragraphs.value = 1;
};
</script>
