<template>
  <section class="relative">
    <div class="hidden lg:block absolute top-0 left-0">
      <img
        src="../../assets/svgs/2-edged-orange-semi-curve.svg"
        alt="orange curve"
      />
    </div>
    <div class="hidden lg:block absolute top-[18%] right-5">
      <img src="../../assets/svgs/green-circle.svg" alt="green circle" />
    </div>
    <div class="hidden lg:block absolute top-1/2 right-0">
      <img
        src="../../assets/svgs/right-curved-orange-semi-circle.svg"
        alt="orange circle"
      />
    </div>
    <div class="hidden lg:block absolute bottom-0 left-3">
      <img src="../../assets/svgs/green-circle.svg" alt="green circle" />
    </div>

    <div class="container px-4 md:px-6 py-20 md:py-36 relative">
      <div class="max-w-[1085px] mx-auto">
        <div class="flex flex-col gap-10 md:gap-[72px]">
          <div v-for="(pledge, index) in pledges" :key="index">
            <h2
              class="font-libreBaskerville font-bold text-2xl md:text-[4rem] mb-6 leading-[31.2px] md:leading-[79.36px]"
            >
              {{ index + 1 }}. {{ pledge.title }}
            </h2>
            <div
              class="max-w-[1056px] text-DavyGrey text-sm md:text-2xl leading-[18.2px] md:leading-[31.2px]"
            >
              <p v-for="(item, index) in pledge.pledge">
                {{ item }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="mt-6 md:mt-7 text-DavyGrey text-sm md:text-2xl leading-[18.2px] md:leading-[31.2px]"
        >
          <p class="mb-6 md:mb-7">
            By volunteering with Turbham Foundation, I acknowledge and agree to
            abide by this Service Pledge.
          </p>
          <p>
            My commitment to these principles is vital to our collective success
            in making a meaningful difference in the lives of those we serve.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const pledges = [
  {
    title: "Integrity and Professionalism",
    pledge: [
      "I will conduct myself with honesty, integrity, and professionalism at all times.",
      "I will represent Turbham Foundation in a positive light, respecting the values and mission of the organization.",
    ],
  },
  {
    title: "Commitment to Excellence",
    pledge: [
      "I will strive for excellence in all my volunteer activities.",
      "I will dedicate my time and skills to the best of my ability, contributing positively to our initiatives.",
    ],
  },
  {
    title: "Respect for Diversity and Inclusion",
    pledge: [
      "I will embrace and respect the diversity of our community and fellow volunteers.",
      "I will foster an inclusive environment that welcomes individuals from all backgrounds.",
    ],
  },
  {
    title: "Confidentiality",
    pledge: [
      "I will safeguard sensitive information obtained during my volunteer service.",
      "I will respect the privacy of individuals and maintain confidentiality in all interactions.",
    ],
  },
  {
    title: "Timeliness and Communication",
    pledge: [
      "I will arrive punctually for scheduled activities and events.",
      "I will communicate promptly with Turbham Foundation regarding my availability, challenges, or any changes to my commitment.",
    ],
  },
  {
    title: "Health and Safety",
    pledge: [
      "I will prioritize the safety and well-being of myself and others during volunteer activities.",
      "I will adhere to all safety guidelines and protocols provided by Turbham Foundation.",
    ],
  },
  {
    title: "Continuous Learning",
    pledge: [
      "I will stay informed about Turbham Foundation's mission, goals, and initiatives.",
      "I will engage in continuous learning and development to enhance my skills and knowledge.",
    ],
  },
  {
    title: "Adaptability and Flexibility",
    pledge: [
      "I will be adaptable and flexible in responding to the evolving needs of Turbham Foundation.",
      "I will demonstrate resilience and a positive attitude in the face of challenges.",
    ],
  },
  {
    title: "No Discrimination and Harassment",
    pledge: [
      "I will treat everyone with dignity and respect, regardless of race, ethnicity, gender, religion, or any other characteristic.",
      " I will report any incidents of discrimination or harassment to Turbham Foundation promptly.",
    ],
  },
];
</script>
