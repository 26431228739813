<template>
  <section>
    <div class="container px-4 md:px-6 py-20">
      <div
        class="pt-[75px] px-4 md:px-8 pb-20 border sm:border-2 border-FrenchGrey rounded"
      >
        <div class="mb-24">
          <div
            class="text-Grey text-base md:text-2xl font-basisGrotesquePro-medium mb-20"
          >
            <h2
              class="text-Dune text-xl md:text-[2rem] font-basisGrotesquePro-bold mb-6"
            >
              Volunteer Coordinator
            </h2>
            <div class="flex flex-col gap-5">
              <p class="flex items-center gap-2">
                <img
                  src="../../assets/svgs/location-icon.svg"
                  alt="location icon"
                />
                <span>Turbham Foundation House, Abuja Nigeria.</span>
              </p>
              <p class="flex items-center gap-2">
                <img src="../../assets/svgs/pay-icon.svg" alt="pay icon" />
                <span>
                  1,000,000 NGN to 2,400,000 NGN per Annum(depending on
                  experience)
                </span>
              </p>
              <p class="flex items-center gap-2">
                <img src="../../assets/svgs/clock-light.svg" alt="clock icon" />
                <span>
                  5 days per week (Flexible days of work between Monday to
                  Friday)
                </span>
              </p>
            </div>
          </div>

          <div
            class="text-sm md:text-2xl font-basisGrotesquePro flex flex-col gap-16"
          >
            <div>
              <h3
                class="text-base md:text-2xl font-basisGrotesquePro-bold mb-5"
              >
                About the job
              </h3>
              <p>
                Turbham Foundation is seeking a dedicated professional with a
                strong customer service background to fill the role of
                Volunteering Coordinator. This position is crucial for providing
                optimal support to our volunteers, with a primary focus on
                coordinating and enhancing the volunteer experience on all of
                our projects. The role is integral in cultivating long-term
                supporters and advocates for Turbham Foundation's mission to
                address global housing challenges.
              </p>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">Flexibility:</h4>
              <p>
                As Turbham Foundation is expanding its activities to end poverty
                housing, the organization values adaptability and seeks a
                dynamic and flexible staff team. The Volunteering Coordinator is
                expected to be flexible in terms of location (within reason),
                line management, and duties and responsibilities.
              </p>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                Role Requirements:
              </h4>

              <p>
                The successful candidate should have a desire to contribute
                significantly to achieving the organization’s vision and
                mission. They should possess a warm and enthusiastic manner, be
                self-motivated, proactive, and demonstrate the ability to work
                well within a team with empathy for Turbham Foundation's values.
                Occasional weekend work will be necessary.
              </p>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">Duties</h4>

              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Work with the Head of Supporter Services and Turbham
                  Foundation teams overseas to plan and implement Build trips,
                  including maintaining the build planner, tracking
                  participants’ fundraising, finalizing budgets and itineraries,
                  liaising with the finance team on funds transfers, and other
                  necessary arrangements.
                </li>
                <li>
                  Assist in managing long-term, highly skilled volunteers (Team
                  Leaders) through hands-on support, training delivery, and
                  consistent communications.
                </li>
                <li>
                  Support long-term engagement of volunteers through challenge
                  events, volunteering opportunities, and community fundraising.
                </li>
                <li>
                  Administer the CRM system, including creating new contacts,
                  logging activities, creating tasks, running reports, and other
                  tasks as required.
                </li>
                <li>
                  Implement and review new volunteer-related systems and
                  processes as necessary.
                </li>
                <li>Maintain project plans for special events.</li>
                <li>
                  Collaborate closely with members of the Supporter Services and
                  Income Generation teams to provide an exemplary donor and
                  volunteer journey experience for all Turbham Foundation
                  supporters.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">Experience</h4>

              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>Strong customer service background.</li>
                <li>Experience in creative problem-solving.</li>
                <li>
                  Demonstrated ability to work with people at all levels and
                  from diverse backgrounds.
                </li>
                <li>
                  High level of computer literacy, including Microsoft Outlook,
                  Word, Excel, and PowerPoint, and CRM Databases.
                </li>
                <li>
                  Financial literacy with the ability to interpret and monitor
                  budgets
                </li>
              </ul>
            </div>

            <div>
              <h4 class="mb-4 font-basisGrotesquePro-bold">
                Skills and Aptitudes:
              </h4>

              <ul
                class="pl-4 md:pl-6 list-decimal flex flex-col gap-6 mb-[72px]"
              >
                <li>
                  Excellent written and verbal communication and presentation
                  skills, with the ability to represent the organization
                  effectively both internally and externally.
                </li>
                <li>
                  Excellent interpersonal skills, capable of making and
                  developing relationships with volunteers, donors, team
                  leaders, and Area Office and international staff.
                </li>
                <li>Strong organizational skills with attention to detail.</li>
                <li>
                  Good time management and flexibility to work on a range of
                  tasks to ensure deadlines are met in all areas of the
                  department.
                </li>
                <li>
                  Personable, proactive, and positive. A solutions-centered
                  approach to problems and enthusiasm for continuous
                  improvement.
                </li>
                <li>Commitment to professional development and teamwork.</li>
                <li>Ability to adapt to shifting priorities and contexts.</li>
                <li>Creativity and resourcefulness.</li>
                <li>
                  Ability to analyze complex documents and disseminate
                  information to a wide range of audiences.
                </li>
              </ul>
            </div>

            <div>
              <h4 class="font-basisGrotesquePro-bold">How to Apply:</h4>
              <p>
                To apply, please submit your resume, cover letter, and examples
                of successful grant applications to
                recruiting@turbhamfoundation.org. Turbham Foundation is an equal
                opportunity employer, and we encourage individuals from diverse
                backgrounds to apply.
              </p>
            </div>
          </div>
        </div>

        <div>
          <router-link
            to="#"
            class="font-basisGrotesquePro-bold bg-GreenHaze p-4 md:py-5 md:px-[26px] rounded-[40px] text-white text-base text-center"
          >
            Apply Now
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>
