export default function useRoutes() {
  const headerRoutes = [
    { name: "aboutUs", path: "/about-us", text: "About Us" },
    { name: "projects", path: "/projects", text: "Projects" },
    { name: "contactUs", path: "/contact-us", text: "Contact Us" },
  ];

  return {
    headerRoutes,
  };
}
