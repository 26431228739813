<template>
  <section class="min-h-screen">
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <div class="text-center mb-28">
        <h2
          class="font-libreBaskerville font-bold max-w-[1158px] mx-auto text-RangoonGreen text-[2rem] md:text-5xl lg:text-[5.5rem] mb-14"
        >
          Benefits
          <span class="inline-flex items-center"
            ><ColoredCircle
              class="bg-HalloweenOrange w-[22px] h-[22px] md:w-7 md:h-7 lg:w-[53px] lg:h-[53px] mt-1"
            />f</span
          >
          Partnersh<span
            class="relative after:content-['\00a0'] after:absolute after:top-2 md:after:top-3 lg:after:top-5 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-2 after:h-2 md:after:w-3 md:after:h-3 lg:after:w-5 lg:after:h-5 after:bg-GreenHaze after:rounded-full"
            >i</span
          >p
        </h2>
        <p class="text-DavyGrey text-sm md:text-[1.375rem]">
          Discover the multi-faceted benefits of partnering with our foundation
          for lasting impact
        </p>
      </div>

      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 place-items-center gap-7"
      >
        <Card
          v-for="(card, index) in cardsInfo"
          :key="index"
          :cardDetails="card"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "../ui/ColoredCircle.vue";
import Card from "@/components/volunteer/Card.vue";
import image1 from "@/assets/images/volunteer-with-children.png";
import image2 from "@/assets/images/impact-reports.png";
import image3 from "@/assets/images/networking.png";

const cardsInfo = [
  {
    image: image1,
    title: "Recognition",
    description:
      "Enjoy acknowledgment on our website, social media platforms, and promotional materials, showcasing your commitment to creating a better world.",
  },
  {
    image: image2,
    title: "Impact Reports",
    description:
      "Stay informed about the tangible impact of your contributions through regular reports and updates, demonstrating the concrete results of your collaboration.",
  },
  {
    image: image3,
    title: "Networking",
    description:
      "Connect with like-minded individuals and organizations within our growing network, fostering valuable relationships that extend beyond the scope of individual initiatives.",
  },
];
</script>
