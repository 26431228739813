<template>
  <section>
    <div class="container px-4 md:px-6 py-20 md:py-36">
      <h2
        class="font-libreBaskerville font-bold text-[2rem] md:text-5xl lg:text-[4.75rem] text-center lg:leading-[98.8px] mb-6 md:mb-28"
      >
        <ColoredCircle
          class="bg-HalloweenOrange w-6 h-6 md:w-7 md:h-7 lg:w-[72px] lg:h-[72px] mr-0.5"
        />utreaches
      </h2>
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 md:gap-x-7 gap-y-14 md:gap-y-[75px] justify-between"
      >
        <Card
          v-for="(project, index) in projects"
          :key="index"
          :cardDetails="project"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "@/components/ui/ColoredCircle.vue";
import Card from "./Card.vue";
import image1 from "@/assets/images/spelling-bee-outreach.png";
import image2 from "@/assets/images/diabetes-walk-outreach.png";
import image3 from "@/assets/images/bwari-lga-outreach.png";
import image4 from "@/assets/images/yoga-diabetes-outreach.png";
import image5 from "@/assets/images/deidei-community-outreach.png";

const projects = [
  {
    image: image1,
    title: "Diabetes Root and Family Spelling Bee Outreach",
    campaign: "Spelling out a healthier tomorrow",
    date: "November 11, 2023",
    path: "spelling-bee-outreach",
  },
  {
    image: image2,
    title: "Diabetes Walk Outreach (Asokoro LGA Abuja)",
    campaign: "Know your blood sugar",
    date: "November 25, 2023",
    path: "know-your-blood-sugar",
  },
  {
    image: image3,
    title: "Bwari LGA Outreach (Zhiko PHC Abuja)",
    campaign: "Know your blood sugar",
    date: "November 30, 2023",
    path: "medical-outreach",
  },
  {
    image: image4,
    title: "Yoga and Diabetes Outreach (Maitama LGA Abuja)",
    campaign: "Diabetes and exercise",
    date: "December 12, 2023",
    path: "#",
  },
  {
    image: image5,
    title: "Deidei Community Outreach",
    campaign: "Know your blood sugar",
    date: "January 19, 2024",
    path: "#",
  },
];
</script>
