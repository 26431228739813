<template>
  <div class="w-max flex flex-nowrap gap-2 md:gap-6">
    <div class="flex gap-2 md:gap-6">
      <div
        v-for="(item, index) in images"
        :key="index"
        @click="!isGallery && goToGallery()"
        class="w-[180px] md:w-[476px] h-[140px] md:h-[264px] rounded-[5px] md:rounded-[10px] overflow-hidden flex justify-center items-center relative group cursor-pointer"
      >
        <span
          v-if="!isGallery"
          class="font-urbanist font-semibold text-white text-sm md:text-2xl absolute z-20 hidden group-hover:block"
          >View image</span
        >
        <img
          :src="item.image"
          alt=""
          class="w-full h-full object-cover transition-all duration-300"
          :class="!isGallery && 'group-hover:brightness-50'"
        />
      </div>
    </div>

    <div class="flex gap-2 md:gap-6 motion-reduce:hidden">
      <div
        v-for="(item, index) in images"
        :key="index"
        @click="!isGallery && goToGallery()"
        class="w-[180px] md:w-[476px] h-[140px] md:h-[264px] rounded-[5px] md:rounded-[10px] overflow-hidden flex justify-center items-center relative group cursor-pointer"
      >
        <span
          v-if="!isGallery"
          class="font-urbanist font-semibold text-white text-sm md:text-2xl absolute z-20 hidden group-hover:block"
          >View image</span
        >
        <img
          :src="item.image"
          alt=""
          class="w-full h-full object-cover"
          :class="!isGallery && 'group-hover:brightness-50'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";

defineProps({
  images: Array,
});

const route = useRoute();
const router = useRouter();

const goToGallery = () => {
  router.push("/media-gallery");
};

const isGallery = route.name === "mediaGallery";
</script>
