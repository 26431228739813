<template>
  <div class="overflow-x-scroll no-scrollbar mb-8">
    <PicturesCarousel
      class="animate-carousel2-mobile md:animate-carousel2 image-carousel motion-reduce:animate-none motion-reduce:-translate-x-6"
      :images="images"
    />
  </div>
</template>

<script setup>
import PicturesCarousel from "./PicturesCarousel.vue";
import image1 from "@/assets/images/picture-1.png";
import image2 from "@/assets/images/picture-2.png";
import image3 from "@/assets/images/picture-3.png";
import image4 from "@/assets/images/picture-4.png";
import image5 from "@/assets/images/picture-5.png";
import image6 from "@/assets/images/picture-6.png";

const images = [
  {
    image: image1,
    alt: "",
  },
  {
    image: image2,
    alt: "",
  },
  {
    image: image3,
    alt: "",
  },
  {
    image: image4,
    alt: "",
  },
  {
    image: image5,
    alt: "",
  },
  {
    image: image6,
    alt: "",
  },
];
</script>
