<template>
  <Hero>
    <template #bgImages>
      <div
        class="absolute top-0 lg:top-[8%] -right-20 scale-50 lg:scale-75 xl:scale-100 -rotate-[22.5deg] lg:rotate-0"
      >
        <img src="../../assets/svgs/orange-curve.svg" alt="orange curve" />
      </div>
    </template>

    <template #title
      ><div
        class="container font-libreBaskerville font-bold text-clamp overflow-x-scroll no-scrollbar"
      >
        <div
          class="w-max flex items-center gap-10 lg:gap-12 animate-contactUsTitle-Carousel-mobile lg:animate-contactUsTitle-Carousel motion-reduce:animate-none lg:motion-reduce:animate-none"
        >
          <div
            class="shrink-0 flex items-center flex-wrap gap-[14px] lg:gap-12"
          >
            <ColoredCircle
              class="bg-HalloweenOrange w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>
          <div class="shrink-0 flex items-center gap-[14px] lg:gap-12">
            <ColoredCircle
              class="bg-GreenHaze w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>

          <div
            class="shrink-0 flex items-center flex-wrap gap-[14px] lg:gap-12"
          >
            <ColoredCircle
              class="bg-HalloweenOrange w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>
          <div class="shrink-0 flex items-center gap-[14px] lg:gap-12">
            <ColoredCircle
              class="bg-GreenHaze w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>

          <div
            class="shrink-0 flex items-center flex-wrap gap-[14px] lg:gap-12"
          >
            <ColoredCircle
              class="bg-HalloweenOrange w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>
          <div class="shrink-0 flex items-center gap-[14px] lg:gap-12">
            <ColoredCircle
              class="bg-GreenHaze w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>

          <div
            class="shrink-0 flex items-center flex-wrap gap-[14px] lg:gap-12"
          >
            <ColoredCircle
              class="bg-HalloweenOrange w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>
          <div class="shrink-0 flex items-center gap-[14px] lg:gap-12">
            <ColoredCircle
              class="bg-GreenHaze w-[42px] h-[42px] md:w-20 md:h-20 xl:w-[107px] xl:h-[107px]"
            />
            <h2>Contact us</h2>
          </div>
        </div>
      </div>
    </template>

    <template #image>
      <div class="mt-20 md:mt-56 relative md:container md:px-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-10 place-items-center">
          <div class="overflow-hidden w-full">
            <img
              src="../../assets/images/two-boys-laughing-1.png"
              alt="two boys laughing"
              class="md:hidden w-full"
            />
            <img
              src="../../assets/images/two-boys-laughing-2.png"
              alt="two boys laughing"
              class="hidden md:block"
            />
          </div>

          <form
            @submit.prevent="handleSubmit"
            class="flex flex-col gap-9 px-4 md:px-0 w-full"
          >
            <div>
              <label for="name" class="text-DavyGrey text-base">Name</label>
              <input
                type="text"
                id="name"
                class="w-full border-b border-GreyGoose outline-none"
                required
                v-model="name"
              />
            </div>
            <div>
              <label for="email" class="text-DavyGrey text-base"
                >Email Address</label
              >
              <input
                type="email"
                id="email"
                class="w-full border-b border-GreyGoose outline-none"
                required
                v-model="email"
              />
            </div>
            <div>
              <label for="message" class="text-DavyGrey text-base"
                >Message</label
              >
              <textarea
                id="message"
                class="w-full border-b border-GreyGoose outline-none"
                required
                v-model="message"
              ></textarea>
            </div>

            <button
              :disabled="loading"
              class="w-full max-w-[588px] mx-auto bg-GreenHaze p-4 md:p-5 rounded-[40px] text-white text-sm md:text-xl font-bold"
            >
              {{ loading ? "Submitting..." : "Send Message" }}
            </button>
          </form>
        </div>
      </div>
    </template>
  </Hero>
</template>

<script setup>
import Hero from "@/components/ui/Hero.vue";
import ColoredCircle from "@/components/ui/ColoredCircle.vue";
import { ref } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const name = ref("");
const email = ref("");
const message = ref("");
const loading = ref(false);

const url = `${process.env.VUE_APP_BASE_URL}/contact-us`;

const handleSubmit = async () => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name.value,
      email: email.value,
      message: message.value,
    }),
  };
  try {
    loading.value = true;
    const res = await fetch(url, options);
    if (!res.ok) {
      throw new Error("Error submitting message");
    } else {
      name.value = "";
      email.value = "";
      message.value = "";
      toast.success("Submitted successful", {
        autoClose: 2000,
      });
    }
  } catch (error) {
    console.log("Error:", error);
    toast.error("Something went wrong", {
      autoClose: 2000,
    });
  } finally {
    loading.value = false;
  }
};
</script>
