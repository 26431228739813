<template>
  <section>
    <div class="min-h-screen container px-4 md:px-6 py-28">
      <div
        class="flex flex-col lg:flex-row justify-between gap-5 md:gap-10 md:mb-11"
      >
        <h1
          class="font-libreBaskerville font-bold md:font-normal text-[2rem] md:text-7xl lg:text-[6.25rem] text-center lg:text-left"
        >
          Ways
          <span class="inline-flex items-center"
            >t<ColoredCircle
              class="bg-HalloweenOrange w-5 h-5 md:w-12 md:h-12 lg:w-14 lg:h-14 mt-2"
          /></span>
          Partner
        </h1>
        <p
          class="lg:self-end lg:max-w-[549px] text-DavyGrey text-sm md:text-[1.375rem] text-center lg:text-left md:leading-[28.6px]"
        >
          Here we believe in the transformative power of connection, compassion
          and collective action. Your journey towards making meaningful impact
          starts here.
        </p>
      </div>

      <Impact
        v-for="(item, index) in impacts"
        :key="index"
        :index="index"
        :item="item"
      />
    </div>
  </section>
</template>

<script setup>
import ColoredCircle from "@/components/ui/ColoredCircle.vue";
import Impact from "@/components/homepage/whatWeDo/Impact.vue";
import image1 from "@/assets/images/man-holding-a-box.png";
import image2 from "@/assets/images/hand-holding-a-microphone.png";
import image3 from "@/assets/images/hands-holding-a-fruit.png";
import image4 from "@/assets/images/alliances.png";

const impacts = [
  {
    title: "Financial Donations or Corporate Sponsorships",
    paragraphs: [
      "Support our projects through impactful financial contributions and align your brand with a cause that resonates with your corporate values. Direct monetary support to Turbham Foundation helps us to deliver our life-saving and life-changing development programs.",
      "It also enables us to respond to emergencies, taking immediate action wherever and whenever the need is greatest. Unrestricted funding in particular is invaluable to our network, as it gives us the flexibility to put money where it is needed most.",
    ],
    image: image1,
  },
  {
    title: "Cause Marketing",
    paragraphs: [
      "Collaborate on campaigns and events that not only raise awareness but also mobilize funds for critical issues that demand collective attention.",
    ],
    image: image2,
  },
  {
    title: "In-Kind Contributions",
    paragraphs: [
      "Contribute goods or services directly to support our programs and initiatives, making a tangible difference in the lives of those we aim to empower.",
    ],
    image: image3,
  },
  {
    title: "Strategic Alliances",
    paragraphs: [
      "Explore opportunities for long-term, strategic partnerships that go beyond immediate impact and contribute to sustained positive change.",
    ],
    image: image4,
  },
];
</script>
